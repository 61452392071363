import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { useNonPersistedStore } from '../store/nonPersistedStore';
import { Button, ButtonGroup, Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import DamHeader from '../components/DamHeader';
import LeftMenu from '../components/LeftMenu';

import { ReactComponent as Visibility } from '../assest/svg/eye.svg';
import {ReactComponent as Edit} from '../assest/svg/editicon.svg';
import {ReactComponent as Delete} from '../assest/svg/deleteicon.svg';
import {ReactComponent as Download} from '../assest/svg/downloadicon.svg';
import Loader from '../components/Loader';
import DamPortalModal from '../components/DamPortalModal';
import { Box } from '@mui/system';
import { showSuccessToast, showErrorToast } from '../assest/Toaster';
import { cartDownloadApi, clearCart, customEditCartApi, deleteCartItem, getUserCart } from '../api/postRequest';
import Footer from '../components/Footer';

import {withStyles} from "@material-ui/core/styles";
import PaginationComponent from '../components/PaginationComponent';
import { useStore } from '../store/persistedStore';
import { ReactComponent as DownArrowDesc } from "../assest/svg/sortDown.svg";
import { ReactComponent as UpArrowAsc } from "../assest/svg/sortUp.svg";
import { useNavigate } from 'react-router-dom';
import commonMessages from '../utils/commomMessages';

const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);

const CartPage = () => {

  const [cartItems, setCartItems] = useState([])

  const [isPreviewModalCart, setIsPreviewModalCart] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [wohParticularRow, setWohParticularRow] = useState([]);


  const [currentPage, setCurrentPage] = useState(1);
  const [cartEditCustomModal, setCartEditCustomModal] = useState(false);
  const [loopMenuPopId, setLoopMenuPopId] = useState(null);
  const [classSort, setClassSort] = useState();
  const[checkedAll,setCheckedAll]= useState(false)
  const[checkedAllButton,setCheckedAllButton]= useState(false)

  const[clearCartModal,setClearCartModal]= useState(false)

  
  
  const navigate = useNavigate();
  
  const {
    setMainPageListingArr,  totalCountCartZustand,
    setTotalCountCartZustand
    
  } = useStore();
  const pageSize = 12; // Set your page size here
  const mutationGetUserCart = useMutation((post) => getUserCart(post), {
    onSuccess: (result) => {
      if(result?.data?.success){
        let output = result.data?.data?.entries;
        const res = output?.map((obj) => ({
          ...obj,
          ["isChecked"]: false,
        }));

        
        setCartItems(res)
       
        setTotalCountCartZustand(result?.data?.data?.totalResults);
      } else {
        // showErrorToast(result.data?.message);
      }
    },
    onError: (error) => {},
  });




  



  const mutationClearUserCart = useMutation((post) => clearCart(post), {
    onSuccess: (result) => {
      if (result?.data?.success) {
        showSuccessToast(result.data?.message);
        mutationGetUserCart.mutate();
        handleCloseModal()
      } else {
        showErrorToast(result.data?.message);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    let payload = { currentPage: currentPage };
    mutationGetUserCart.mutate(payload);
  }, []);

  const handleClearCart = () => {
    setClearCartModal(true);
    // mutationClearUserCart.mutate()
  }

  const mutationDeleteCartItem = useMutation((post) => deleteCartItem(post), {
    onSuccess: (result) => {
      if(result?.data?.success){
        showSuccessToast(result.data?.message)
        if ( cartItems.filter(item => item.isChecked === true).length == cartItems?.length) {
          let payload = {
            currentPage: Math.max(currentPage - 1, 1),  // Ensure page number is at least 1
           
          };
         
          
          mutationGetUserCart.mutate(payload)

        } else{
          let payload = {
            currentPage: currentPage ,
           
          };
          mutationGetUserCart.mutate(payload)
        }
        setCheckedAll(false)
        if(cartItems.filter(item => item.isChecked === true).length == cartItems?.length){
          setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));  //
          }
       
      } else {
        showErrorToast(result.data?.message);
      }
    },
    onError: (error) => {},
  });
  const mutationDownloadCartSelected = useMutation((post) => cartDownloadApi(post), {
    onSuccess: (result) => {
      if(result?.data?.success){
        showSuccessToast(result.data?.message)
        window.open(
          result?.data?.url,"_self"
          // "_blank"
        );
      
      } else {
        showErrorToast(result.data?.message);
      }
    },
    onError: (error) => {},
  });
  const customEditCartApiMutation = useMutation(
    (post) => customEditCartApi(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
           

            handleCloseModal();
            mutationGetUserCart.mutate();
            showSuccessToast(result.data?.message);
          } else {
            showErrorToast(result.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );
  const handleDeleteItem = (itemKey) => {
    let payload = { itemKey : itemKey }
    mutationDeleteCartItem.mutate(payload)
  }

  const handleDownload = (id, itemkeyParam) => {
    let payload = { itemKey: JSON.stringify([itemkeyParam]) };

    mutationDownloadCartSelected.mutate(payload);
  };
  const handlePageChange = (newPage) => {
    if (newPage === 0) {
      return;
    } else {
      setCurrentPage(newPage);
      
      setCheckedAll(false)

      let payload = {
        currentPage: newPage,

        // sortBy: sortingZusNon && sortingZusNon[0],
        // sortOrder: sortingZusNon && sortingZusNon[1],
      };
      mutationGetUserCart.mutate(payload);
    }
  };

  const handleCheck = (e, obj) => {
    const isChecked = e.target.checked;
  
    // Update individual item state
    const updatedCartItems = cartItems.map((item) => 
      item.elementId === obj.elementId
        ? { ...item, isChecked: !item.isChecked } // Toggle the checked state
        : item
    );
  
    // Update state for items
    setCartItems(updatedCartItems);
  
    // Check if all items are now checked
    const allChecked = updatedCartItems.every(item => item.isChecked);
    setCheckedAll(allChecked);
  };


  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
  
    // Update all items' checked state
    const updatedCartItems = cartItems.map((item) => ({
      ...item,
      isChecked
    }));
  
    // Update state for items and checkbox
    setCartItems(updatedCartItems);
    setCheckedAll(isChecked);
  };

 
  const handleDeleteSelected = () => {
    let selectedItemKeys = [];
    cartItems?.forEach((value) => {
      if (value?.isChecked) {
        selectedItemKeys.push(value?.itemKey);
      }
    });
    let payload = { itemKey: selectedItemKeys?.toString() };
    mutationDeleteCartItem.mutate(payload);
  };

  const handleDownloadSelected = () => {
    let selectedItemKeys = [];
    cartItems?.forEach((value) => {
      if (value?.isChecked) {
        selectedItemKeys.push(value?.itemKey);
      }
    });

    let payload = { itemKey: JSON.stringify(selectedItemKeys) };

    mutationDownloadCartSelected.mutate( payload);
  };

  const handleCloseModal = () => {
    setCartEditCustomModal(false);
    setClearCartModal(false)
    setIsPreviewModalCart(false)
  };

  const handleSingleDownload = (structuredData, assetData, customData) => {
    let configArr = [];

    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }

    let payload = {
      itemKey: loopMenuPopId?.itemKey,

      configs: JSON.stringify(configArr),
    };
    customEditCartApiMutation.mutate(payload);
  };

  const handleCallbackEditCart = (structuredData, assetData, customData) => {
 
    handleSingleDownload(structuredData, assetData, customData);
    // if (asset) {
    //   handleSingleDownload(asset, structuredData, assetData, customData);
    // } else {
    //   handleBulkDownload(structuredData, assetData, customData);
    // }
  };
  const handleCallbackClearCart = () => {
    mutationClearUserCart.mutate()
 
  
   
  };

  const handleEditModal = (id, path, name, row) => {
    setCartEditCustomModal(true);
    setLoopMenuPopId(row);

    // if (id) {
    //   setAsset(id);
    // }
  };
  const handlePreview = ( itemKey,
    elementPath,
    elementName,
    row,cartItemPreview) => {


    setIsPreviewModalCart(true);

    // setFileUrl(`https://dam-api.pimcoredemos.com${elementPath}${elementName}`);
    setFileUrl(cartItemPreview)
    setWohParticularRow(row)
};

  const handleConfigs = (row, configs) => {
 
    if (configs?.length > 1) {
      return configs?.map((item, idx) => {
        return (
          <Box>
            
            {configs[idx]?.type === "structuredData"
              ? "Metadata"
              : "Asset"}: {configs[idx]?.format}
            {idx !== configs?.length - 1 && ""}
          </Box>
        );
      });
    } else {
      return (
        <Box>
          {configs[0]?.type === "structuredData" ? "Metadata" : "Asset"}: {configs[0]?.format}
        </Box>
      );
    }
  };

  


const handleAscSort=()=>{
  setClassSort("ASC");
  let carItemsClone = cartItems;
  carItemsClone?.sort((a,b)=>a.elementName.localeCompare(b.elementName))
  setCartItems(carItemsClone)
}

const handleDescSort=()=>{
  setClassSort("DSC");
  let carItemsClone = cartItems;
  carItemsClone?.sort((a,b)=>b.elementName.localeCompare(a.elementName))
  setCartItems(carItemsClone)
}
const handleNavigation = (id) => {
  navigate({
    pathname: "/detailPage",
    search: `id=${id}`,
  });
};


  return (
    <>
      {mutationGetUserCart?.isLoading && <Loader></Loader>}

      {customEditCartApiMutation?.isLoading && <Loader></Loader>}
      <Box>
        <DamPortalModal
          open={isPreviewModalCart}
          onClose={handleCloseModal}
          fileUrl={fileUrl}
          titleMessage={"Preview file"}
          isPreviewModalCart={isPreviewModalCart}
          detailPageDatta={wohParticularRow}
         
        />
      </Box>

      <Box>
        <DamPortalModal
          open={clearCartModal}
          clearCartModal={clearCartModal}
          onClose={handleCloseModal}
          titleMessage={commonMessages?.clearCart}
          mutationClearUserCart={mutationClearUserCart}
          handleCallbackClearCart={handleCallbackClearCart}
        />
      </Box>
      <Box>
        <DamPortalModal
          open={cartEditCustomModal}
          editCartCustomModalFlag={cartEditCustomModal}
          onClose={handleCloseModal}
          loopMenuPopId={loopMenuPopId}
          titleMessage={"Update cart item"}
          handleCallbackEditCart={handleCallbackEditCart}
        />
      </Box>
      <Grid container className="container bg">
        <Grid item xs={1} className="menu">
          <LeftMenu />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ minHeight: "calc(100% - 50px)" }}
          className="rightPart"
        >
          <DamHeader />

          {/* <Selector /> */}
          <Grid container className="bodyPart">
            <Box className="selector">
              <Box className="breadcrumb">
                {/* Files <RightArrow />{" "} */}
                <Typography variant="h5" component="h5" className="heading">
                  Cart{" "}
                </Typography>
              </Box>
              {cartItems?.length !== 0 && (
                <Box className="buttonGroup">
                  <Button
                    size="small"
                    // startIcon={<LeftArrow />}
                    className="button"
                    onClick={() => handleClearCart()}
                  >
                    Clear cart
                  </Button>
                  <Button
                    size="small"
                    className="button"
                    onClick={() => handleDeleteSelected()}
                    variant="filled"
                    disabled={
                      !(cartItems?.filter((value) => {
                        return value?.isChecked == true;
                      })).length > 0 || mutationDeleteCartItem?.isLoading
                    }
                  >
                    Delete selected
                  </Button>
                  <Button
                    size="small"
                    className="button"
                    onClick={() => handleDownloadSelected()}
                    variant="filled"
                    disabled={
                      !(cartItems?.filter((value) => {
                        return value?.isChecked == true;
                      })).length > 0
                    }
                  >
                    Download selected
                  </Button>
                </Box>
              )}
            </Box>

            {!mutationGetUserCart?.isLoading ? (
              cartItems?.length ? (
                <>
                  <TableContainer
                    sx={{ padding: "1em", marginTop: "var(--sideSetHeight)" }}
                  >
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      className="table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan="1" component="th">
                            <Checkbox
                              checked={checkedAll}
                              name=""
                              className="checkbox"
                              onClick={handleCheckAll}
                              disabled={cartItems?.length == 0}
                            />
                          </TableCell>
                          <TableCell component="th">Asset</TableCell>
                          <TableCell component="th">
                            <Box className="flex">
                              Name{" "}
                              <Box className="flex paddingL-sm arrow">
                                <UpArrowAsc
                                  className={classSort == "ASC" ? "active" : ""}
                                  height={20}
                                  onClick={() => handleAscSort()}
                                />
                                <DownArrowDesc
                                  className={classSort == "DSC" ? "active" : ""}
                                  height={20}
                                  onClick={() => handleDescSort()}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell component="th" align="center">
                            Download Config
                          </TableCell>
                          <TableCell component="th" align="center">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cartItems?.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                            <TableCell width="2%" align="center">
                              <Checkbox
                                checked={row?.isChecked}
                                name=""
                                className="checkbox"
                                onClick={(e) => handleCheck(e, row)}
                              />
                            </TableCell>

                            <TableCell width="10%">
                              <img
                                // src={`${row?.elementURL}`}
                                src={`${"https://"}${"dam-api.pimcoredemos.com"}${
                                  row?.thumbnail
                                }`}
                                className="responsive"
                              />
                            </TableCell>

                            <TableCell
                              sx={{
                                cursor: "pointer",
                              }}
                              width="60%"
                              onClick={() => {
                                handleNavigation(row?.elementId);
                              }}
                            >
                              <Typography> {row?.elementName}</Typography>
                            </TableCell>
                            <TableCell width="20%">
                              <Typography>
                                {handleConfigs(row, row?.configs)}
                              </Typography>
                            </TableCell>
                            <TableCell align="center" width="18%">
                              <Box className="buttonGroup">
                           
                              <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title="Preview"
                                >
                                  <Button
                                    size="small"
                                    className="button icon"
                                    onClick={() =>
                                      handlePreview(
                                        row?.itemKey,
                                        row?.elementPath,
                                        row?.elementName,
                                        row,row?.cartItemPreview
                                      )
                                    }
                                  >
                                    <Visibility />
                                  </Button>
                                </TooltipStyle>
                                <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title="Edit"
                                >
                                  <Button
                                    size="small"
                                    className="button icon"
                                    onClick={() =>
                                      handleEditModal(
                                        row?.itemKey,
                                        row?.elementPath,
                                        row?.elementName,
                                        row
                                      )
                                    }
                                  >
                                    <Edit />
                                  </Button>
                                </TooltipStyle>
                                <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title="Download"
                                >
                                  <Button
                                    size="small"
                                    className="button icon"
                                    onClick={() => {
                                      handleDownload(
                                        row?.elementId,
                                        row?.itemKey
                                      );
                                    }}
                                  >
                                    <Download />
                                  </Button>
                                </TooltipStyle>
                                <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title="Delete"
                                >
                                  <Button
                                    size="small"
                                    className="button icon"
                                    disabled={mutationDeleteCartItem?.isLoading}
                                    onClick={() => {
                                      handleDeleteItem(row?.itemKey);
                                    }}
                                  >
                                    <Delete />
                                  </Button>
                                </TooltipStyle>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Box
                      className="paginationWrapper"
                      sx={{ height: "80px", position: "relative" }}
                    >
                      <PaginationComponent
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalCount={totalCountCartZustand}
                        pageSize={pageSize}
                        onPageChange={handlePageChange}
                        itemCount={cartItems?.length || 0}
                      />
                    </Box>
                  </TableContainer>
                </>
              ) : (
                <>
                  {" "}
                  <Box
                    className="notificationWrapper col-12"
                    sx={{ padding: "1em", marginTop: "var(--sideSetHeight)" }}
                  >
                    <Box class="notification">No items present in the cart.</Box>
                  </Box>
                </>
              )
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}

export default CartPage;