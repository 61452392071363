import { Button } from '@mui/material';
import React from 'react';
import { ReactComponent as Arrow } from "../assest/svg/chevronRight.svg";

const Breadcrumb = ({ breadcrumbs, onFolderClick }) => {
    return (
        <nav aria-label="breadcrumb" className='breadcrumb'>
            <ol style={{ listStyle: 'none', display: 'flex', padding: 0 }}>
                { breadcrumbs  && breadcrumbs?.map((item, index) => (
                    <li key={item?.folderId} className='flex'>
                        <Button 
                            onClick={() => onFolderClick(item?.folderId,item?.label,item?.permissions)}
                            style={{ 
                                background: 'none', 
                                border: 'none', 
                                color: item?.active ? 'var(--primary)' : "#323338", // Change color based on active state
                                cursor: item?.active ? 'pointer' : 'not-allowed', // Change cursor based on active state, 
                                textDecoration: item?.active ? 'underline' : 'none' 
                            }}
                            className='button link'
                            disabled={!item?.active}
                        >
                            {item.label}
                        </Button>
                        {index < breadcrumbs?.length - 1 && (
                            <span style={{ margin: '0 4px' }} className='flex'><Arrow height={20} /></span>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumb;

