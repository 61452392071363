import { Box, Button, Chip, Divider, Grid, IconButton,Modal, InputAdornment, List, ListItemIcon, Menu, MenuItem, TextField,FormControl,InputLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {ReactComponent as DownArrow} from '../assest/svg/chevronDown.svg';

import {ReactComponent as Filter} from '../assest/svg/filter.svg';
import {ReactComponent as ClearAll} from '../assest/svg/clearAll.svg';

import { useLocation, useNavigate } from 'react-router-dom';
import { Mutation, useMutation } from '@tanstack/react-query';
import { getHeaderListFilters } from '../api/getRequest';
import { deleteSavedFiltersApi, getAllFiltersApi, getFirstPageListing, saveFilterApi } from '../api/postRequest';
import { useStore } from '../store/persistedStore';
import Loader from './Loader';
import { useNonPersistedStore } from '../store/nonPersistedStore';
import { ReactComponent as Search } from '../assest/svg/search.svg';
import DatePicker from "react-datepicker";
import Select  from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import {ReactComponent as Close} from '../assest/svg/cross.svg';
import {ReactComponent as CloseMe} from '../assest/svg/close.svg';

import { menuStyles, paperPropsStyles } from "../styledComponents/MenuStyles"; 
import { ReactComponent as ChevronBack } from "../assest/svg/chevronLeft.svg";
import moment from "moment";
import CustomMoment from 'moment-timezone'; 
import { showErrorToast, showSuccessToast } from '../assest/Toaster';
import commonMessages from '../utils/commomMessages';
import DamPortalModal from './DamPortalModal';

const SortMenu = ({
  headerFilterList,
  handleKeyPressCallback,
  setselectedModiDate,
  selectedModiDate,setIsAllChecked,
  selectedCreationDate,
  setselectedCreationDate,  currentPage,
  setCurrentPage,
  selectedValueType,
  setSelectedValueType,
  selectedValueTypeMime,
  setSelectedValueTypeMime,
  savedFilterDropdownSelectedLocal,
  setSavedFilterDropdownSelectedLocal,totalGetFilterData,
  setTotalGetFilterData, setFilterLabelValue,filterLabelValue,handleClearCompleteFilter,setLoader,setInternalSearchValue,internalSearchValue
}) => {
  const {
    setMainPageListingArr,
    mainPageListingArr,
    setTotalCountZustand,
    setFiltersZustand,
    totalCountZustand,
    clickedFolderZus,
  } = useStore();
  const {
    clickedFolderZusNon,
    applyFilterBtnCreateNewZusNon,
    setApplyFilterBtnCreateNewZusNon,
    setApplyFilterBtnSavedFilterZusNon,
    applyFilterBtnSavedFilterZusNon,
    selectedFilterZusNon,
    applyFilterBtnSavedFilterCreateNewZusNon,
    setApplyFilterBtnSavedFilterCreateNewZusNon,
    setSelectedFilterZusNon,
    setIsNewFilterOpenZusNon,
    isNewFilterOpenZusNon,
    setSelectedMimeTypeZusNon,
    filterMasalaZusNon,
    setFilterMasalaZusNon,
    setFilterLabelValueForDeleteZusNon,
    filterLabelValueForDeleteZusNon,
    setSearchValueZusNon,searchValueZusNon,setPayloadForSortZusNon
  } = useNonPersistedStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [clickedMenu, setClickedMenu] = useState();
  const [deleteFilterInPopUp, setDeleteFilterInPopUp] = useState();
 
  const [filterNameModal, setFilterNameModal] = useState();
  const [createNewFilterButtonClicked, setCreateNewFilterButtonClicked] =
    useState(false);

  const [typeOfAssetClearState, setTypeOfAssetClearState] = useState(true);

  const [mimeTypeClearState, setMimeTypeClearState] = useState(true);
  const [modiDateClearState, setModiDateClearState] = useState(true);
  const [mimeTypesFilterOutState, setMimeTypesFilterOutState] = useState([]);
  
  const [creationDateClearState, setCreationDateClearState] = useState(true);

  const [filterLabelValueForDelete, setFilterLabelValueForDelete] = useState();
  const location = useLocation();
  const [selectedFilter, setSelectedFilter] = useState();
  const [savedFilters, setSavedFilters] = useState([
    "Filter 1",
    "Filter 2",
    "Filter 3",
  ]);
  const [isNewFilterOpen, setIsNewFilterOpen] = useState(false);
  const [isNewFilterOpenExisting, setIsNewFilterOpenExisting] = useState(false);
  // const [selectedModiDate, setselectedModiDate] = useState();
  // const [selectedCreationDate, setselectedCreationDate] = useState();
  // const [selectedValueType, setSelectedValueType] = useState();
  // const [selectedValueTypeMime, setSelectedValueTypeMime] = useState();
  const [savePopUpInputBoxVAlue, setSavePopUpInputBoxVAlue] = useState();

  const [configSelectedData, setConfigSelectedData] = useState();

  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [filterOptions, setFilterOptions] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });



  const assetTypesFilterOut =
    headerFilterList?.filters?.map((filter) => ({
      label: filter.assetType.label,
      value: filter.assetType.value,
    })) || [];
  const mimeTypesFilterOut = selectedValueType
    ? headerFilterList?.filters
        ?.find((filter) => filter.assetType.value === selectedValueType?.value)
        ?.mimeTypes.map((mime) => ({
          label: mime.label,
          value: mime.value,
        })) || []
    : [];
  

  // useEffect(() => {
  //   const mimeTypesFilterOut = selectedValueType
  //   ? headerFilterList?.filters?.find(filter => filter.assetType.value === selectedValueType?.value)?.mimeTypes.map(mime => ({
  //       label: mime.label,
  //       value: mime.value
  //   })) || []
  //   : [];
  //   console.log("mimeTypesF123ilterOut",mimeTypesFilterOut,selectedValueType);
  //   setMimeTypesFilterOutState(mimeTypesFilterOut)

  // }, [selectedValueType])

  const mutation = useMutation((post) => getFirstPageListing(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          let output = result.data?.data?.items;
          const newArray = output?.map((obj) => ({
            ...obj,
            ["isChecked"]: false,
          }));

          setMainPageListingArr(newArray);
          setTotalCountZustand(result.data?.data?.totalResults);
          if (savedFilterDropdownSelectedLocal) {
            setSelectedFilterZusNon(savedFilterDropdownSelectedLocal);
            resetFilters();
          } else {
            resetFilters();
          }

          handleCloseMenu();
        } else {
        }
      }
    },
    onError: (error) => {},
  });

  function getConfigByFilter(filterObj, data) {
   
    const foundConfig =
    filterObj && data &&
      data?.find(
        (item) =>
          item.filterName.label === filterObj.label &&
          item.filterName.value === filterObj.value
      );
    //  foundConfig ? foundConfig.config : null;

    if (foundConfig) {
      setFilterLabelValueForDelete(foundConfig?.filterName);
      setFilterLabelValueForDeleteZusNon(foundConfig?.filterName);
      setSelectedFilter(foundConfig?.filterName?.label);
      setConfigSelectedData(foundConfig?.config);
      setSelectedValueTypeMime(foundConfig?.config[0]?.mimeType);
      setSelectedValueType(foundConfig?.config[0]?.assetType);
      setselectedModiDate(foundConfig?.config[0]?.modificationDate);
      setselectedCreationDate(foundConfig?.config[0]?.creationDate);
      // let payload ={
      //   selectedValueTypeMime:foundConfig?.config[0]?.mimeType,
      //   selectedModiDate:foundConfig?.config[0]?.modificationDate,
      //   selectedCreationDate:foundConfig?.config[0]?.creationDate,
      //   selectedValueType:foundConfig?.config[0]?.assetType
      // }
      // setFilterMasalaZusNon(payload)
    }
  }

  const getAllFiltersApimutation = useMutation(
    (post) => getAllFiltersApi(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            setTotalGetFilterData(result?.data?.data);
            const output = result?.data?.data.reduce((acc, curr) => {
              let obj = { filterName: curr.filterName };
              acc.push(obj);
              return acc;
            }, []);
            const labelValue = output?.map((item) => {
              return {
                label: item?.filterName?.label,
                value: item?.filterName?.value,
              };
            });
            setFilterLabelValue(labelValue);
          } else {
          }
        }
      },
      onError: (error) => {},
    }
  );

  const saveFilterApiMutation = useMutation((post) => saveFilterApi(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          getAllFiltersApimutation.mutate();
          showSuccessToast(result?.data?.message);
          setAnchorEl(null);
          setAnchorEl1(null);
          setAnchorElFilter(null);

          setIsNewFilterOpen(false);
          setIsNewFilterOpenZusNon(false);

          handleCloseModal();
        } else {
          showErrorToast(result?.data?.message);
        }
      }
    },
    onError: (error) => {},
  });
  const handleCheckSelectedFilter = (item) => {
    if (
      selectedFilterZusNon === null ||
      (selectedFilterZusNon &&
        typeof selectedFilterZusNon === "object" &&
        Object.keys(selectedFilterZusNon)?.length === 0)
    ) {
      return false;
    } else {

      if(savedFilterDropdownSelectedLocal?.value === selectedFilterZusNon?.value){
        
        handleClearCompleteFilter()
      }
      
    }
  };

 
  const deleteFilterMutation = useMutation(
    (post) => deleteSavedFiltersApi(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            getAllFiltersApimutation.mutate();
            showSuccessToast(result?.data?.message);
            handleCheckSelectedFilter();
            handleClearValuesFilter();
            handleClearSavedFilter();
            setAnchorEl(null);
            setAnchorEl1(null);
            setAnchorElFilter(null);

            setIsNewFilterOpen(false);
            setIsNewFilterOpenZusNon(false);
            handleCloseModal();
          } else {
            showErrorToast(result?.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const savedFilterOptions = [
    { label: "en", value: "en" },
    { label: "fe", value: "fe" },
    { label: "dr", value: "dr" },
  ];

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const openFilter = Boolean(anchorElFilter);
  const handleClickAsset = (event, item) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickMime = (event, item) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose = (item) => {
    let payload = {
      mimetype: item?.value,
    };
    setAnchorEl(null);
    setSavePopUpInputBoxVAlue("");
    // mutation.mutate(payload)
  };
  const handleOnChangeSave = (selectedOptions) => {
    setSelectedValueType(selectedOptions);
    setSelectedValueTypeMime(null); 

    // setFilterMasalaZusNon({...filterMasalaZusNon, selectedValueType:selectedOptions})

    //  setIsNewFilterOpenExisting(true)
  };
  const handleClear = (event) => {
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); 
    setTypeOfAssetClearState(false);
    setSelectedValueType(null);
   
  };

const handleChangeInternalSearch = (e) => {
  let value = e.target.value;
  
  // Remove leading spaces
  if (value.startsWith(' ')) {
    value = value.trimStart();
  }
  
  // Limit to 50 characters
  if (value.length > 50) {
    value = value.slice(0, 50);
  }

  setSearchValueZusNon(value);
};

  const handleClearMimeType = (event) => {
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); 
    setMimeTypeClearState(false);
    setSelectedValueTypeMime(null);
   
  };
  const handleClearSavedFilter= (event) => {
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); 
   resetFilters()
   
  };
  const handleClearModiType = () => {
    setModiDateClearState(false);
    setselectedModiDate(null);
  };
  const handleClearCreationType = () => {
    setCreationDateClearState(false);
    setselectedCreationDate(null);
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="custom-datepicker-input">
      <input type="text" value={value} onClick={onClick} readOnly />
      {value && <Close onClick={handleClearModiType} />}
    </div>
  );

  const CustomInputCreationDate = ({ value, onClick }) => (
    <div className="custom-datepicker-input">
      <input type="text" value={value} onClick={onClick} readOnly />
      {value && <Close onClick={handleClearCreationType} />}
    </div>
  );
 
  const handleOnChangeSavedFilters = (selectedOptions) => {
  
    if(selectedOptions?.value){
      getConfigByFilter(selectedOptions, totalGetFilterData);
      setSavedFilterDropdownSelectedLocal(selectedOptions)
    } else{
      setSavedFilterDropdownSelectedLocal(null)
    }
   
   
  

    
  };

  

  const handleOnChangeSaveMime = (selectedOptions) => {
    setSelectedValueTypeMime(selectedOptions);
  };

  const handleCreationDateChange = (date) => {
    if (selectedModiDate && date < selectedModiDate) {
      return; // If invalid, do not update "to" date
    }
    setselectedCreationDate(date);
    let payload = {
      selectedValueTypeMime: selectedValueTypeMime,
      selectedModiDate: selectedModiDate,
      selectedCreationDate: selectedCreationDate,
      selectedValueType: selectedValueType,
    };
    // setFilterMasalaZusNon({...filterMasalaZusNon, selectedCreationDate:date})
  };

  const handleModificationDateChange = (date) => {
    setselectedModiDate(date);

    // setFilterMasalaZusNon({...filterMasalaZusNon, selectedModiDate:date})
    // Validate if "to" date is greater than "from" date
    if (selectedCreationDate && date > selectedCreationDate) {
      setselectedCreationDate(null); // Reset "to" date if it's invalid
      // setFilterMasalaZusNon({...filterMasalaZusNon, selectedModiDate:null})
    }
  };

  const handleCloseMime = (item) => {
    let payload = {
      mimetype: item?.value,
    };
    setFiltersZustand(payload);
    setSelectedMimeTypeZusNon(payload);
    setAnchorEl1(null);
    mutation.mutate(payload);
  };
  const handleCloseMenu = (item) => {
    setAnchorEl(null);
    setAnchorEl1(null);
    setAnchorElFilter(null);
    // setIsNewFilterOpen(false);
  };
  const handleFilterBack = (item) => {
    setIsNewFilterOpen(false);
    setIsNewFilterOpenZusNon(false);
    setCreateNewFilterButtonClicked(false);
  };

  const handleNavigation = (data) => {
    navigate({
      pathname: "/uploadAssetsPage",
    });
  };

  const handleNavigationFolder = (data) => {
    navigate({
      pathname: "/manageFolderPage",
    });
  };
  function checkURL(url) {
    return url.includes("/searchResultsPage");
  }

  const handleOpen = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  
  const handleClearValuesFilter = () => {
    resetFilters()
   
  };
  const resetFilters = () => {
    setSelectedValueType(null);
    setSelectedValueTypeMime(null);
    setselectedModiDate(null);
    setselectedCreationDate(null);
    setSavedFilterDropdownSelectedLocal(null)
   
  };

  const handleCloseFilter = (item) => {
    
    resetFilters();
    let payload = {
      mimetype: item?.value,
    };

    setAnchorElFilter(null);
    // setIsNewFilterOpen(false);
  };

  const handleFilterChange = (event, filterName) => {
    setFilterOptions({
      ...filterOptions,
      [filterName]: event.target.value,
    });
  };
  
  const handleApplyFilter = () => {
    setCurrentPage(1)
    setIsAllChecked(false)
    
    if (savedFilterDropdownSelectedLocal) {
      
      let payloadMAsala = {
        selectedValueTypeMime: selectedValueTypeMime,
        selectedModiDate: selectedModiDate,
        selectedCreationDate: selectedCreationDate,
        selectedValueType: selectedValueType,
      };
     
      setFilterMasalaZusNon(payloadMAsala);
      // setApplyFilterBtnSavedFilterZusNon(true);
     
   
     
      let payload = {
        assetType: selectedValueType?.value,
        mimeType: selectedValueTypeMime?.value,

        startDate:
          selectedModiDate &&
          CustomMoment(selectedModiDate)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD"),
        endDate:
          selectedCreationDate &&
          CustomMoment(selectedCreationDate)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD"),
        folderId: clickedFolderZusNon?.id,
        searchTerm:internalSearchValue
      };
  
      setPayloadForSortZusNon(payload);
     
      setAnchorElFilter(null);
  
      mutation.mutate(payload);
     
    } else {
     
    

      let payloadMAsala = {
        selectedValueTypeMime: selectedValueTypeMime,
        selectedModiDate:
          selectedModiDate &&
          CustomMoment(selectedModiDate)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD"),
        selectedCreationDate:
          selectedCreationDate &&
          CustomMoment(selectedCreationDate)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD"),
        selectedValueType: selectedValueType,
      };

    
      setFilterMasalaZusNon(payloadMAsala);
   

    

      setSelectedFilterZusNon(null);
     

      let payload = {
        assetType: selectedValueType?.value,
        mimeType: selectedValueTypeMime?.value,

        startDate:
          selectedModiDate &&
          CustomMoment(selectedModiDate)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD"),
        endDate:
          selectedCreationDate &&
          CustomMoment(selectedCreationDate)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD"),
        folderId: clickedFolderZusNon?.id,
        searchTerm:internalSearchValue
      };
 
      setPayloadForSortZusNon(payload);
      setAnchorElFilter(null);
      //setSearchValueZusNon("");
      //setInternalSearchValue("");
      mutation.mutate(payload);
    }
  };

  useEffect(() => {
    if (!applyFilterBtnCreateNewZusNon && !applyFilterBtnSavedFilterZusNon) {
      // setFilterMasalaZusNon(null)
      handleFilterBack();
    }
    if (applyFilterBtnSavedFilterZusNon) {
      // setSelectedValueType(null);
      // setSelectedValueTypeMime(null);
      // setselectedModiDate(null);
      // setselectedCreationDate(null);
      setTypeOfAssetClearState(true);
      setMimeTypeClearState(true);
      setCreationDateClearState(true);
      setModiDateClearState(true);
      if (selectedFilterZusNon) {
        setCreateNewFilterButtonClicked(false);
      }
    }
    if (applyFilterBtnSavedFilterCreateNewZusNon) {
    }

    // anchorEl,anchorEl1,
  }, [anchorElFilter]);


  const handleSaveAsNewFilter = () => {
    setSavePopUpInputBoxVAlue("");
    setFilterNameModal(true);
  };
  const handleDeleteFilter = () => {
    setDeleteFilterInPopUp(true)
 
  };
  const handleCallbackDeleteFilterInPopUp = () => {
  
    let payload = {
      filterId:savedFilterDropdownSelectedLocal && savedFilterDropdownSelectedLocal?.value,
    };
  
     deleteFilterMutation.mutate(payload);
  };

  // const handleClearFilter = () => {
  //   setSelectedValueType(null);
  //   setSelectedValueTypeMime(null);
  //   setselectedModiDate(null);
  //   setselectedCreationDate(null);
  //   setApplyFilterBtnSavedFilterCreateNewZusNon(false);

  //   let payloadMAsala = {
  //     selectedValueTypeMime: null,
  //     selectedModiDate: null,
  //     selectedCreationDate: null,
  //     selectedValueType: null,
  //     folderId: clickedFolderZusNon?.id,
  //   };
  //   setFilterMasalaZusNon(payloadMAsala);
  //   setSelectedFilterZusNon("");

  //   mutation.mutate(payloadMAsala);
  // };

  const handleSaveAsNewFilterPopUp = () => {
    let payload = {
      filter: [
        {
          assetType: selectedValueType ?? filterMasalaZusNon?.selectedValueType,
          mimeType:
            selectedValueTypeMime ?? filterMasalaZusNon?.selectedValueTypeMime,

          creationDate:
            (selectedCreationDate &&
              CustomMoment(selectedCreationDate)
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD")) ??
            (filterMasalaZusNon?.selectedCreationDate &&
              CustomMoment(filterMasalaZusNon?.selectedCreationDate)
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD")),

          modificationDate:
            (selectedModiDate &&
              CustomMoment(selectedModiDate)
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD")) ??
            (filterMasalaZusNon?.selectedModiDate &&
              CustomMoment(filterMasalaZusNon?.selectedModiDate)
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD")),

          filterName: savePopUpInputBoxVAlue,
        },
      ],
    };

    if (savePopUpInputBoxVAlue) {
      saveFilterApiMutation.mutate(payload);
    }
  };

  const handleCloseModal = () => {
    setFilterNameModal(false);
    setSavePopUpInputBoxVAlue(null);
    setDeleteFilterInPopUp(false)
  };




  const handleSavedFilterSelect = (filterName) => {};

  const handleSavePopupFn = (e) => {
    setSavePopUpInputBoxVAlue(e.target.value);
  };

  useEffect(() => {
    getAllFiltersApimutation.mutate();
  }, []);
  const isButtonDisabled = () => {
    const isInputFilled = savePopUpInputBoxVAlue?.trim() !== "";
    const isAnySelectFilled =
      selectedValueType ||
      selectedValueTypeMime ||
      selectedModiDate ||
      selectedCreationDate;

    return !(savePopUpInputBoxVAlue?.trim() !== "" && isAnySelectFilled);
    // return !(isInputFilled)
  };

  const isSaveAsNewFilterButtonDisabled = () => {
    const isAnySelectFilled =
      selectedValueType ||
      selectedValueTypeMime ||
      selectedModiDate ||
      selectedCreationDate;

    return !isAnySelectFilled;
    // return !(isInputFilled)
  };

  const isApplyFilterButtonDisabled = () => {
    const isAnySelectFilled =
      selectedValueType ||
      selectedValueTypeMime ||
      selectedModiDate ||
      selectedCreationDate;

    return !isAnySelectFilled;
  };
  
  useEffect(() => {
    setSavePopUpInputBoxVAlue("");
  }, [filterNameModal]);
 
  useEffect(()=>{setLoader(mutation?.isLoading)},[mutation?.isLoading])

  return (
    <>
      {mutation.isLoading && <Loader></Loader>}
      {saveFilterApiMutation.isLoading && <Loader></Loader>}
      {deleteFilterMutation.isLoading && <Loader></Loader>}
      <>
        <Box>
          <DamPortalModal
            open={deleteFilterInPopUp}
            onClose={handleCloseModal}
            titleMessage={"Delete filter"}
            deleteFilterInPopUp={deleteFilterInPopUp}
            handleCallbackDeleteFilterInPopUp={
              handleCallbackDeleteFilterInPopUp
            }
          />
        </Box>
        <Box>
          <Modal
            open={filterNameModal}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box className="modalWrapper">
              <Box className="modalContainer">
                <Typography component="h5" variant="h5" className="modaltitle">
                  Save as new filter
                  <Close onClick={handleCloseModal} />
                </Typography>

                <Box className="modalContent">
                  <label>Filter name</label>

                  <TextField
                    id="outlined-basic"
                    label=""
                    value={savePopUpInputBoxVAlue}
                    onChange={(e) => {
                      handleSavePopupFn(e);
                    }}
                    // onKeyDown={handleKeyPress}
                    variant="standard"
                  />
                </Box>

                <Box className="buttonGroup">
                  <Button
                    className="button"
                    variant="filled"
                    onClick={handleSaveAsNewFilterPopUp}
                    disabled={isButtonDisabled()}
                  >
                    Save filter
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
        <Box className="buttonGroup">
          {!checkURL(location?.pathname) && (
            <TextField
              id="searchFolderText"
              label=""
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search height={20} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    {searchValueZusNon?.length ? (
                      <CloseMe
                        cursor="pointer"
                        height={18}
                        onClick={() => {
                          setSearchValueZusNon("");
                          document.getElementById("searchFolderText").focus();
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </InputAdornment>
                ),
              }}
              placeholder="Search"
              className="blank searchField"
              variant="standard"
              value={searchValueZusNon}
              onChange={handleChangeInternalSearch}
              // onChange={(e) => setSearchValueZusNon(e.target.value)}
              onKeyDown={(e) => handleKeyPressCallback(e, searchValueZusNon)}
            />
          )}

          <Menu
            anchorEl={anchorEl}
            id="assetType"
            open={open}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
            {...paperPropsStyles}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <>
              {headerFilterList?.assetTypeFilter?.map((itm, indx) => (
                <MenuItem onClick={() => handleClose(itm?.value)}>
                  {itm.label}
                  {/* <Chip label={itm?.value} /> */}
                </MenuItem>
              ))}
            </>
          </Menu>

          <Menu
            anchorEl={anchorEl1}
            id="assetType"
            open={open1}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
            {...paperPropsStyles}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <>
              {" "}
              {headerFilterList?.mimetypeFilter?.map((itm, indx) => (
                <MenuItem onClick={() => handleCloseMime(itm)}>
                  {itm?.label}
                  <Chip label={itm?.value} />
                </MenuItem>
              ))}
            </>
          </Menu>

          <Button
            onClick={handleOpen}
            size="small"
            sx={{ marginLeft: "10px" }}
            aria-controls={openFilter ? "assetType" : undefined}
            aria-haspopup="true"
            aria-expanded={openFilter ? "true" : undefined}
            startIcon={<Filter />}
            endIcon={<DownArrow />}
            // className={`menuButton ${item.menuId===clickedMenu ? "active":""}`}
            className="menuButton"
          >
            Filter
          </Button>
          <Menu
            anchorEl={anchorElFilter}
            open={openFilter}
            onClose={handleCloseFilter}
            className="filterMenu"
            {...paperPropsStyles}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
          >
            <Box className="modalContainer " sx={{ width: "600px" }}>
              <Typography component="h5" variant="h5" className="modaltitle">
                Filter
              </Typography>

              <>
                <Box className="modalContent simpleScroll">
                  <Box className="notification ">
                    {commonMessages?.filterInstructionMsg}
                  </Box>

                  <Box
                    onClick={() => handleSavedFilterSelect("dddd")}
                    className="selectBoxFilter mt"
                  >
                    <label>
                      {" "}
                      <b>Saved filters</b>
                    </label>
                    <Select
                      // defaultValue={filterLabelValueForDeleteZusNon}
                      className="filterDropdown"
                      options={filterLabelValue}
                      value={savedFilterDropdownSelectedLocal}
                      menuPosition="fixed"
                      isClearable
                      components={{
                        ClearIndicator: (props) => (
                          <div
                            {...props.innerProps}
                            onMouseDown={(event) => {
                              handleClearSavedFilter(event);
                            }}
                            className="clearAll"
                          >
                            <Close />
                          </div>
                        ),
                      }}
                      // components={{
                      //   ClearIndicator: () => (
                      //     <Close onClick={handleClearSavedFilter}  className='clearAll' />
                      //   ),
                      // }}
                      onChange={(e) => handleOnChangeSavedFilters(e)}
                    />
                  </Box>

                  {/* <Box className="notification mt">
                    {commonMessages?.applyingFilterInstructionMsg}
                  </Box> */}

                  {!savedFilterDropdownSelectedLocal && (
                    <Box className="mt">
                      {" "}
                      <b>Custom filter</b>{" "}
                    </Box>
                  )}

                  <Box className="mt">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <label>Type of asset</label>
                        <Select
                          options={assetTypesFilterOut}
                          // options={headerFilterList?.assetTypeFilter}
                          // value={
                          //   selectedValueType
                          //     ? selectedValueType

                          //     : typeOfAssetClearState
                          //     ? filterMasalaZusNon?.selectedValueType
                          //     : null
                          // }
                          value={selectedValueType}
                          // value={ filterMasalaZusNon?.selectedValueType }
                          // defaultValue={configSelectedData[0]?.assetType}
                          onChange={handleOnChangeSave}
                          menuPosition="fixed"
                          className="filterDropdown"
                          isClearable
                          components={{
                            ClearIndicator: (props) => (
                              <div
                                {...props.innerProps}
                                onMouseDown={(event) => {
                                  handleClear(event);
                                }}
                                className="clearAll"
                              >
                                <Close />
                              </div>
                            ),
                          }}
                          // components={{
                          //   // DropdownIndicator: null,
                          //   // IndicatorSeparator: null, // Remove the default indicator separator
                          //   ClearIndicator: () => (
                          //     <Close onClick={handleClear} className='clearAll'/>
                          //     // <div onClick={handleClear}>Clear</div> // Custom clear indicator
                          //   ),
                          // }}

                          //  isClearable={true}
                          // isSearchable={false}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <label>Mime type</label>
                        <Select
                          // isMulti
                          options={mimeTypesFilterOut}
                          // options={mimeTypesFilterOutState}
                          // options={headerFilterList?.mimetypeFilter}
                          value={selectedValueTypeMime}
                          // value={ filterMasalaZusNon?.selectedValueTypeMime }
                          // value={
                          //   selectedValueTypeMime
                          //     ? selectedValueTypeMime
                          //     : createNewFilterButtonClicked
                          //     ? null
                          //     : mimeTypeClearState
                          //     ? filterMasalaZusNon?.selectedValueTypeMime
                          //     : null
                          // }
                          isDisabled={!selectedValueType}
                          onChange={handleOnChangeSaveMime}
                          menuPosition="fixed"
                          className="filterDropdown"
                          isClearable
                          components={{
                            ClearIndicator: (props) => (
                              <div
                                {...props.innerProps}
                                onMouseDown={(event) => {
                                  handleClearMimeType(event);
                                }}
                                className="clearAll"
                              >
                                <Close />
                              </div>
                            ),
                          }}
                          // components={{
                          //   ClearIndicator: () => (
                          //     <Close onClick={handleClearMimeType}  className='clearAll' />
                          //   ),
                          // }}

                          // defaultValue={   configSelectedData[0]?.mimeType}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className="mt">
                    <label>Last modified</label>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="flex">
                        {/* <label>Last Modified</label> */}
                        <label className="dateLabel">From</label>
                        <DatePicker
                          showIcon
                          placeholder="From  date"
                          placeholderText="From date"
                          toggleCalendarOnIconClick
                          onFocus={(e) => (e.target.readOnly = true)}
                          // onChange={(date) => field.onChange(date ? date : "")}
                          onChange={(date) =>
                            handleModificationDateChange(date)
                          }
                          selected={selectedModiDate}
                          // selected={
                          //   selectedModiDate
                          //     ? selectedModiDate
                          //     : createNewFilterButtonClicked
                          //     ? null
                          //     : modiDateClearState
                          //     ? filterMasalaZusNon?.selectedModiDate
                          //     : null
                          // }
                          inputMode="none"
                          // startDate={selectedModiDate}
                          //  endDate={selectedCreationDate}
                          maxDate={moment().toDate()}
                          showYearDropdown
                          yearDropdownItemNumber={60}
                          scrollableYearDropdown
                          // isClearable={true}
                          customInput={<CustomInput />}
                        />
                      </Grid>

                      <Grid item xs={6} className="flex">
                        {/* <label>To date</label> */}
                        <label className="dateLabel">To</label>

                        <DatePicker
                          showIcon
                          placeholderText="To date"
                          toggleCalendarOnIconClick
                          onFocus={(e) => (e.target.readOnly = true)}
                          // onChange={(date) => field.onChange(date ? date : "")}
                          onChange={(date) => handleCreationDateChange(date)}
                          selected={selectedCreationDate}
                          // selected={
                          //   selectedCreationDate
                          //     ? selectedCreationDate
                          //     : createNewFilterButtonClicked
                          //     ? null
                          //     : creationDateClearState
                          //     ? filterMasalaZusNon?.selectedCreationDate
                          //     : null
                          // }
                          inputMode="none"
                          maxDate={moment().toDate()}
                          // startDate={selectedModiDate}
                          // endDate={selectedCreationDate}
                          minDate={selectedModiDate}
                          showYearDropdown
                          yearDropdownItemNumber={60}
                          scrollableYearDropdown
                          // isClearable={true}
                          customInput={<CustomInputCreationDate />}
                          // defaultValue={ configSelectedData[0]?.creationDate}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box className="buttonGroup" justifyContent="space-between">
                  <Box>
                    <Button
                      variant="filled"
                      onClick={handleApplyFilter}
                      className="button"
                      disabled={isApplyFilterButtonDisabled()}
                    >
                      Apply filter
                    </Button>

                    <Button
                      variant="outlined"
                      onClick={handleClearValuesFilter}
                      className="button marginL-xs"
                    >
                      Clear filter
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleDeleteFilter}
                      className="button marginL-xs"
                      disabled={!savedFilterDropdownSelectedLocal}
                    >
                      Delete filter
                    </Button>
                  </Box>
                </Box>
              </>
            </Box>
          </Menu>
        </Box>
      </>
    </>
  );
};

export default SortMenu
