import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  Card,
  FormGroup,TextareaAutosize,
  CardContent,
  Checkbox,Tooltip,
  Drawer,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import CustomMoment from 'moment-timezone';
import moment from "moment";
import Dropzone from "react-dropzone";
import DatePicker from "react-datepicker";
import MenuItem from "@mui/material/MenuItem";
// import Select from 'react-select';
import {ReactComponent as Share} from '../assest/svg/shareicon.svg';
import { default as ReactSelect } from "react-select";
import Select from "@mui/material/Select";
import { ReactComponent as Close } from "../assest/svg/cross.svg";
import { useStore } from "../store/persistedStore";
import { useCallback } from "react";
import FolderTree from "./FolderTree";
import {
  addFolder,
  deleteFolder,
  getFolders,
  getLanguageApi,
  renameFolder,
} from "../api/postRequest";
import { useMutation } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../assest/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import { useNonPersistedStore } from "../store/nonPersistedStore";
import { ReactComponent as Cross } from "../assest/svg/cross.svg";
import { getCartOption } from "../api/getRequest";
import { ReactComponent as Move } from "../assest/svg/moveicon.svg";
import { inputOptions } from "../config/constants";
import { withStyles } from "@material-ui/core/styles";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import commonMessages from "../utils/commomMessages";
import { useSearchParams } from 'react-router-dom';
import { margin } from "@mui/system";
import Loader from "./Loader";
import {ReactComponent as Noimage} from '../assest/svg/NoImage.svg';


import Audio from '../assest/images/Audio.png'
import Breadcrumb from "./Breadcrumb";
import Skeleton from "react-loading-skeleton";
const DamPortalModal = ({
  open,
  onClose,
  isRenameModal,
  handleCallbackPreview,isPreviewModalCart,isPreviewModal,fileUrl,saveAsNewFilterNameModal,publicSharePSDeleteMutation,
  isRenameCollectionModal,clearCartModal,
  isEditCollectionHistoryModal,
  editCollectionHistoryRowDetail,
  isDeleteCollectionHistoryModal,
  handleCallbackReplaceAsset,handleOnChangeCreateNewCollection,
  replaceAsset,  mutationClearUserCart,
  handleCallbackClearCart,
  deleteAssetFromDetailPage,createNewCollectionMutation,
  deleteAssetFromLandingPage,
  setIsMoveModal,
  addNewCollectionModal,selectedValues, deleteFilterChip,   deleteFilterInPopUp,savePopUpInputBoxVAlue,
  setSavePopUpInputBoxVAlue,
  handleCallbackDeleteFilterInPopUp,mutationAddToCart,
  handleCallbackDeleteFilterChip,
  bulkEditModal,
  isDeletefolderModal,mutationAddToCollection,publicShareCollectionDeleteMutation,
  replaceFile,mutationDeleteCollectionList,
  setReplaceFile,
  addToCollectionModal,
  handleCallbackAddToCollection,
  compareVersionModal,customDownloadApiMutation,replaceAssetFileMutation,
  isDeletePSHistoryModal,
  handleCallbackDeletePSHistory,deleteIdRemoveCollection,handleCallbackCreateNewFilterNameModal,
  handleCallbackCompareVersion = () => {},
  addToCart,
  addToCartModalFlag,
  downloadCustomModalFlag,createNewFilterNameModal,
  editCartCustomModalFlag,
  shareCopyToClipboardModalFlag,mutationBatchUpdate,
  cartEditCustomModal,
  handleCallbackEditCart,saveFilterApiMutation,
  handleCallbackDownload,
  handleCallbackPublicShare,
  deleteCollectionHistoryRowDetail,
  updateProfileModalFlag,
  handleCallbackDeleteCollectionHistory,
  addToCollection,
  titleMessage,
  deleteAsset,
  collectionDeleteModal,
  deletePSHistoryRowDetail,
  moveTofolderModalFlag,
  handleCallbackDelete,
  addToCollectionModalFlag,
  handleCallbackAddNewCollectionModal,
  handleCallbackDeleteFolder,handleOnChangeCollection,
  handleCallbackBulkEdit,
  handleCallbackRenameCollectionModal,collectionListZusNon,mutationDeleteAsset,
  isUploadFileModal,
  block,
  uploadFileTags,
  handleCallbackEditCollectionHistory,
  loopMenuPopId,
  handleCallbackUploadFileModal,searchValueCreateNewCollection,
  handleMoveTofolderCallbackApi,
  handleCallbackUpdateProfile,
  shareCollectioninitialId,deleteAssetFromCollection,
  assetId,
  assetArr,
  manageFolderId,
  isAddfolderModal,breadCrumbsCreateFolderState,setIsAddfolderModal,mutationCreateFolderBreadCrum,
  manageFolderLabel,
  setManageFolderLabel,
  handleCallbackOnDrop,
  collectionName,
  deleteMultiple,
  linkPass,
  handleCallbackAddToCart,
  openDrawer,
  shareCollectionInitialModal,
  handleCallbackShareCollection,
  profileDataFromApi,shareName,setDeleteFolderSuccess,deleteFolderSuccess,nameError,setNameError,detailPageDatta
}) => {
  const [age, setAge] = React.useState("");
  // const [selectedValues, setSelectedValues] = useState();
  const [errorInputCreateFolder, setErrorInputCreateFolder] = useState(false);
  const [profileEmail, setProfileEmail] = useState(profileDataFromApi?.email);
  const [profileFirstName, setProfileFirstName] = useState(
    profileDataFromApi?.firstName
  );
  const [profileLastName, setProfileLastName] = useState(
    profileDataFromApi?.lastName
  );
  const [editUserProfile, setEditUserProfile] = useState(false);
  const navigate = useNavigate();


  const [languageOptions, setLanguageOptions] = useState();
  const [file, setFile] = useState(null);

  const [paramsDataUploadModal, setParamsDataUploadModal] = useState();
  const [metadata, setMetaData] = useState([]);
  const [newMetaData, setNewMetaData] = useState({
    idx: Math.random(),
    name: "",
    language: "",
    type: "",
    data: "",
  });
  const [selectedValuesTagsModal, setSelectedValuesTagsModal] = useState();
  const [inputValueName, setInputValueName] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchValue, setSearchValue] = useState();
  const [searchValueCreate, setSearchValueCreate] = useState();
  const [searchValueRenameLanding, setSearchValueRenameLanding] = useState();
  const [requireChecBox, setRequireChecBox] = useState(false);
  const [inputCommentText, setInputCommentText] = useState();
  const [inputValueUploadFileModal, setInputValueUploadFileModal] = useState();
  const [copied, setCopied] = useState(false);

  const [previewImage, setPreviewImage] = useState(null);
  const [structuredData, setStructuredData] = useState({
    type: "",
    localized: false,
    attribute: null,
    format: "",
    setup: null,
  });
  const [asset, setAsset] = useState({
    type: "",
    localized: false,
    attribute: null,
    format: "",
    setup: null,
  });
  // const [selectedNodeId, setSelectedNodeId] = useState(null);
  // const [selectedNode, setSelectedNode] = useState(null);
  const { folderTreeStructureZus, setFolderTreeStructureZus } = useStore();
  const {
    
    setCollectionListZusNon,
    setClickedFolderZusNon,
    clickedFolderZusNon,
  } = useNonPersistedStore();
  const tomorrow = moment().add(1, "day");
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id')

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [structuredDataOptions, setStructuredDataOptions] = useState();
  const [newUrlLink, setNewUrlLink] = useState();

  const [assetOptions, setAssetOptions] = useState();
  const [customOptions, setCustomOptions] = useState();
  const [customData, setCustomData] = useState({
    format: "",
    height: "",
    width: "",
    quality: "",
  });
  const [expandedNodes, setExpandedNodes] = useState([]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 31,
      minHeight: 31,
      fontSize: "14px",
      margin: "0 10px",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      height: 24,
    }),
    clearIndicator: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      height: 24,
    }),
    // menuPortal: (base) => ({ ...base, zIndex: 9999 })
  };
  const TooltipStyle = withStyles({
    tooltip: {
      color: "var(--black) !important",
      backgroundColor: "var(--checked) !important",
      fontSize: "var(--fontSize) !important",
      "& .MuiTooltip-arrow": {
        color: "var(--checked)",
      },
    },
  })(Tooltip);

  useEffect(() => {
    if (openDrawer === true) {
      document.body.classList.add("overFlowHidden");
    } else {
      document.body.classList.remove("overFlowHidden");
    }
  }, [openDrawer]);
  const getLanguageApiMutation = useMutation((post) => getLanguageApi(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          setLanguageOptions(result?.data?.data?.languages);
        } else {
        }
      }
    },
    onError: (error) => {},
  });

  const mutationGetCartOptions = useMutation((post) => getCartOption(post), {
    mutationKey: "getCartOption",
    method: "GET",
    onSuccess: (result) => {
      if (result?.data[0]?.data?.length) {
        let opt1 = result?.data[0]?.data[0]?.formats.map((value) => {
          return { label: value?.label, value: value?.label };
        });

        setStructuredDataOptions(opt1);

        let opt2 = result?.data[0]?.data[1]?.formats.map((value) => {
          return { label: value?.label, value: value?.id };
        });
        setAssetOptions(opt2);
        let res = result?.data[0]?.data[1]?.formats.find(
          (val) => val?.id === "custom"
        );
        setCustomOptions(res);
        setCustomData({
          ...customData,
          quality: res?.setup?.quality?.defaultValue,
          format: res?.setup?.format?.options[0]?.value,
        });
      }
    },
    onError: (error) => {},
  });

  

  useEffect(() => {
    if (bulkEditModal) {
      getLanguageApiMutation.mutate();
    }
  }, [bulkEditModal]);
  useEffect(() => {
    if (titleMessage === commonMessages?.renameCollection) {
      setSearchValue(collectionName);
    }
    if (titleMessage === commonMessages?.batchMetdataUpdate && open === false) {
      setMetaData([]);
      setSelectedValuesTagsModal();
      setNewMetaData({
        idx: Math.random(),
        name: "",
        language: "",
        type: "",
        data: "",
      });
    }
    if (
      (titleMessage === commonMessages?.addToCart || "Download") &&
      open === false
    ) {
      setStructuredData({
        type: "",
        localized: false,
        attribute: null,
        format: "",
        setup: null,
      });
      setAsset({
        type: "",
        localized: false,
        attribute: null,
        format: "",
        setup: null,
      });
      setCustomData({
        format: "",
        height: "",
        width: "",
        quality: "",
      });
    }

    if (isEditCollectionHistoryModal) {
      let structuredDataItem =
        editCollectionHistoryRowDetail?.config &&
        editCollectionHistoryRowDetail?.config?.find(
          (item) => item.type === "structuredData"
        );

      let assetItem =
        editCollectionHistoryRowDetail?.config &&
        editCollectionHistoryRowDetail?.config?.find(
          (item) => item.type === "asset"
        );

      setInputValueName(editCollectionHistoryRowDetail?.Name);

      setStructuredData(structuredDataItem || structuredData);
      setAsset(assetItem || asset);

      // const assetObject = editCollectionHistoryRowDetail?.config?.filter(
      //   (item) => item.type === "asset"
      // );
      const assetObject = editCollectionHistoryRowDetail?.config?.find(
        (item) => item.type === "asset"
      );

      setCustomData({
        format: assetObject?.setup?.format,
        height: assetObject?.setup?.height,
        width: assetObject?.setup?.width,
        quality: assetObject?.setup?.width,
      });
    }
    if (editCartCustomModalFlag) {
      let structuredDataItem =
        loopMenuPopId?.configs &&
        loopMenuPopId?.configs?.find((item) => item.type === "structuredData");

      let assetItem =
        loopMenuPopId?.configs &&
        loopMenuPopId?.configs?.find((item) => item.type === "asset");

      setStructuredData(structuredDataItem || structuredData);
      setAsset(assetItem || asset);

      const assetObject = loopMenuPopId?.configs?.find(
        (item) => item.type === "asset"
      );

      setCustomData({
        format: assetObject?.setup?.format,
        height: assetObject?.setup?.height,
        width: assetObject?.setup?.width,
        quality: assetObject?.setup?.width,
      });
    }

    if (
      addToCartModalFlag ||
      isEditCollectionHistoryModal ||
      downloadCustomModalFlag ||
      editCartCustomModalFlag
    ) {
      mutationGetCartOptions.mutate();
    }
  }, [open]);

  const handleCommentInputChange = (params) => {
  params?.length <=500 &&  setInputCommentText(params);
  };

  const handleChangeCartConfigType = (type) => {
    if (type === "structuredData") {
      !structuredData?.type
        ? setStructuredData({ ...structuredData, type: "structuredData" })
        : setStructuredData({ ...structuredData, type: "" });
    }
    if (type === "asset") {
      !asset?.type
        ? setAsset({ ...asset, type: "asset" })
        : setAsset({ ...asset, type: "" });
    }
  };
  const handleInputChange = (val, item, index, key) => {
    if (key === "name" && val.trim() === "") {
      return; // Exit the function without updating the state
    }

    let updatedItem = { ...item, [key]: val };

    let res = [...metadata];
    if (key != "delete") {
      res.splice(index, 1, updatedItem);
      setMetaData(res);
    } else {
      res.splice(index, 1);

      setMetaData(res);
    }
  };

  const handleChangeCustom = (value, field) => {
    const numericValue = parseFloat(value);
    if (field === "format") {
      setCustomData({ ...customData, [field]: value?.value });
    } else if (field === "quality") {
      setCustomData({
        ...customData,
        [field]: value > 100 ? 100 : value < 0 ? 1 : value,
      });
    }
    else if (field === "width" || field === "height") {
    
      // const isValidNumeric = !isNaN(numericValue) && numericValue >= (customOptions?.setup?.[field]?.min || 0);
    
      // setCustomData({ ...customData, [field]: isValidNumeric ? numericValue : customData[field] });
      const isValidNumeric = !isNaN(value) && (value<=8000)
    
      setCustomData({ ...customData, [field]: isValidNumeric ? value : customData[field] });
    } else {
      setCustomData({ ...customData, [field]: value });
    }
    
    // else {
    //   setCustomData({ ...customData, [field]: value });
    // }
  };


  

  const handleChangeFormat = (selectedValue, type) => {
    if (type === "structuredData") {
      setStructuredData({ ...structuredData, format: selectedValue?.value });
    }
    if (type === "asset") {
      setAsset({ ...asset, format: selectedValue?.value });
    }
  };

  const handleAddMetadata = () => {
    if (!newMetaData.name) {
      setNameError(true);
      // Display an error message or take appropriate action

      return; // Exit the function if the Name field is empty
    }

    setNameError(false);

    setMetaData([...metadata, newMetaData]);
  };

  const handleRemoveMetaData = (obj) => {
    let res = metadata?.filter((val) => val?.idx !== obj?.idx);
    setMetaData(res);
  };

  const handleChangeNewMetaData = (inputValue, field) => {
    setNewMetaData({ ...newMetaData, [field]: inputValue });

    if (field === "language") {
      setNewMetaData({ ...newMetaData, [field]: inputValue?.value });
    }
    if (field === "type") {
      setNewMetaData({ ...newMetaData, [field]: inputValue?.value });
    }
    // setNewMetaData({...newMetaData, [field]: inputValue})
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeMetaData = (inputValue, obj, field) => {
    let res = metadata?.map((val, ind) => {
      if (val?.idx === obj?.idx) {
        return { ...val, [field]: inputValue };
      } else {
        return val;
      }
    });
    setMetaData(res);
  };

  const handleCheckboXRequireChange = (value) => {
    setRequireChecBox(value);
  };

  const mutationGetFolders = useMutation((post) => getFolders(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          setFolderTreeStructureZus(result?.data?.data?.items);
        } else {
        }
      }
    },
    onError: (error) => {},
  });

  const renameMutation = useMutation((post) => renameFolder(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          let updated = {
            ...clickedFolderZusNon,
            label: searchValueRenameLanding,
          };

          setClickedFolderZusNon(updated);

          showSuccessToast(result.data?.message);
          onClose();
          mutationGetFolders.mutate();
        } else {
          showErrorToast(result.data?.message)
        }
      }
    },
    onError: (error) => {},
  });

  const addFolderMutation = useMutation((post) => addFolder(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          showSuccessToast(result?.data?.message);
          onClose();
          mutationGetFolders.mutate();
        } else {
          showErrorToast(result?.data?.message);
        }
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if(shareCollectionInitialModal) {
      mutationGetCartOptions.mutate();
    }
  }, [shareCollectionInitialModal]);

  const deleteMutation = useMutation((post) => deleteFolder(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          if (folderTreeStructureZus[0]?.clickable) {
            setClickedFolderZusNon({
              label: "",
              id: result?.data?.data?.parent,
              permissions: folderTreeStructureZus[0]?.permissions,
            });
          }
          setDeleteFolderSuccess(true);
          showSuccessToast(result?.data?.message);
          onClose();
          mutationGetFolders.mutate();
          //handleCallbackDeleteFolder();
        } else {
        }
      }
    },
    onError: (error) => {},
  });
  const handleCardClick = (id) => {
    const currentNode = folderTreeStructureZus?.find((node) => node?.id === id);
    if (currentNode?.children) {
      setExpandedNodes((prevNodes) => [...prevNodes, id]);
    }
  };
  const handleNameInputChange = (params) => {
    params?.length<=100 &&  setInputValueName(params);
  };

  const handleRenameClick = () => {
    let payload = {
      assetId: manageFolderId,
      // updatedFolderName:searchValue,
      name: searchValueRenameLanding,
    };
    if (manageFolderId) {
      renameMutation.mutate(payload);
    }
  };

  const handleDeleteFolderClick = () => {
    if (manageFolderId) {
      let payload = {
        elementId: [manageFolderId],
      };
      deleteMutation.mutate(payload);
    }
  };
  const handleAddFolderClick = () => {
    let payload = {
      parentId: manageFolderId,
      // updatedFolderName:searchValue,
      name: searchValueCreate,
    };
    if (manageFolderId) {
      addFolderMutation.mutate(payload);
    }
  };

  // const handleOnChangeCollection = (selectedOptions) => {
  //   setSelectedValues(selectedOptions);
  // };
  const hanldleCallbackMoveToFolder = () => {
    onClose();
  };
  const handleOnChangeTagsModal = (selectedOptions) => {
    setSelectedValuesTagsModal(selectedOptions);
    let data = {
      content: selectedOptions,
      newFilename: inputValueUploadFileModal,
    };

    setParamsDataUploadModal(data);
  };
  const handleOnChangeTextField = (value) => {
    setInputValueUploadFileModal(value);

    let data = {
      content: selectedValuesTagsModal,
      newFilename: value,
    };

    setParamsDataUploadModal(data);
  };

  const location = useLocation();
  useEffect(() => {
    setSearchValueCreate("");
    setErrorInputCreateFolder(false);
  }, [isAddfolderModal]);
  const handleShareBTnClicked = (structuredData, assetData, customData) => {
    if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
      showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);

      return;
    } else if (
      (!structuredData.type && !assetData.format) ||
      (structuredData.type && !structuredData.format) ||
      (assetData.type && !assetData.format)
    ) {
      // Handle validation error, display message to user or prevent API call
      showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
      return;
    }

    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }

    let payload = {
      collectionId: shareCollectioninitialId,
      expiryDate: CustomMoment(selectedDate)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD"),

      name: inputValueName,
      termsText: inputCommentText ?? null,
      config: configArr,
      userId: 2,
      _locale: "en",
      showTermsText: requireChecBox,
    };
    handleCallbackShareCollection(payload);
  };

  const handleCreateFolderInputChange = (value) => {
    // const trimmedValue = value.trimStart();
    if (value.startsWith(' ')) {

      return
    } else{
       // setSearchValueCreate(value)
    const regex = /^[a-zA-Z0-9_-\s]*$/;
    // const regex = /^[a-zA-Z0-9_-]*$/;
   
    const newValue = value;

    // Check if the new value matches the regex pattern
    if (regex.test(newValue) || newValue === "" ) {
      setSearchValueCreate(newValue); // Update the input value if it matches the pattern
      setErrorInputCreateFolder(false); // Clear error state
    } else {
      setSearchValueCreate(newValue);
      setErrorInputCreateFolder(true); // Set error state if pattern doesn't match
    }

    }
   
  };

  const handleRenameFolderInputChange = (value) => {
    if (value.startsWith(" ")) {
      return;
    } else {
      // setSearchValueCreate(value)
      const regex = /^[a-zA-Z0-9_-\s]*$/;
      // const regex = /^[a-zA-Z0-9_-]*$/;
      const newValue = value;

      // Check if the new value matches the regex pattern
      if (regex.test(newValue) || newValue === "") {
        setSearchValueRenameLanding(newValue); // Update the input value if it matches the pattern
        setErrorInputCreateFolder(false); // Clear error state
      } else {
        setSearchValueRenameLanding(newValue);
        setErrorInputCreateFolder(true); // Set error state if pattern doesn't match
      }
    }
  };

  useEffect(() => {
    setErrorInputCreateFolder(false);
    if (isRenameModal) {
      setSearchValueRenameLanding(manageFolderLabel);
    } else {
      setSearchValueRenameLanding("");
    }
  }, [isRenameModal]);
  useEffect(() => {
    if (!shareCopyToClipboardModalFlag) {
      setCopied(false);
    }
  }, [shareCopyToClipboardModalFlag]);

  useEffect(() => {
    // var newUrl = linkPass?.replace(/http:\/\/dam-api\.pimcoredemos\.com\//, " http://localhost:3000/");
    var newUrl = linkPass?.replace(
      /https:\/\/dam-api\.pimcoredemos\.com\//,
      " https://dam-api-frontend.pimcoredemos.com/"
    );

    setNewUrlLink(newUrl);
  }, [shareCopyToClipboardModalFlag]);

  const handleEditUserProfileClick = () => {
    setEditUserProfile(true);
  };

  const handleCancelUserProfileClick = () => {
    setEditUserProfile(false);
  };

  useEffect(() => {
    if (!updateProfileModalFlag) {
      setEditUserProfile(false);
    }
  }, [updateProfileModalFlag]);
  const removeFile = () => {
  
    setFile(null);
    setReplaceFile(null)
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  // const onDrop = useCallback((acceptedFiles) => {
  //   // Ensure only one file is accepted
  //   if (acceptedFiles.length > 0) {
  //     setFile(acceptedFiles[0]);

  //   }
  // }, []);
  const isValidAssetMetadata = () => {
    if (!asset.type && !structuredData.type) {
      return false;
    }
    if ((asset.type && !asset.format) || (structuredData.type && !structuredData.format)) {
      return false;
    }
    return true;
  };

  

  const handleChangeRenameCollectionField = (event) => {
    const value = event.target.value;
    
    // Check if the value exceeds 50 characters and does not start with a space
    if (value.length <= 50 && !value.startsWith(' ')) {
      setSearchValue(value);
    }
   
  };



  const isValidAssetMetadataWithNAme = () => {
    if (!inputValueName || !selectedDate) {
      return false;
    }

    if (!asset.type && !structuredData.type) {
      return false;
    }
    if (
      (asset.type && !asset.format) ||
      (structuredData.type && !structuredData.format)
    ) {
      return false;
    }
    return true;
  };

  const isValidBatchMEtadataUpdate = () => {
    if (
      (metadata === null ||
        metadata === undefined ||
        (Array.isArray(metadata) && metadata.length === 0)) &&
      (selectedValuesTagsModal === null ||
        selectedValuesTagsModal === undefined ||
        (Array.isArray(selectedValuesTagsModal) &&
          selectedValuesTagsModal.length === 0))
    ) {
      return false;
    }

    return true;
  };

  const handleSavePopupFn = (e) => {

    const inputValue = e.target.value;

    // Check if input is valid: max 5 characters and does not start with a space
    if (inputValue.length <= 50 && (inputValue.length === 0 || inputValue[0] !== ' ')) {
      setSavePopUpInputBoxVAlue(inputValue);
    }
    // setSavePopUpInputBoxVAlue(e.target.value);
  };

  const handleKeyPress = (event) => {
    const key = event.key;
    // Prevent entering non-numeric characters except backspace and delete
    if (!/[\d]/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Tab') {
      event.preventDefault();
    }
  };

  const isPreviewableImage = (fileType) => {
    return ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'].includes(fileType);
  };

  const getBlobUrl = (file) => {
    if (file && isPreviewableImage(file?.type)) {
      return URL.createObjectURL(file);
    }
    return null; // No Blob URL for non-previewable file types
  };

  const handleInputChangeReactSelect = (inputValue) => {
    // Restrict input to 5 characters
    if (inputValue.length <= 30) {
      return inputValue;
    }
    return inputValue.slice(0, 30); // Return the first 5 characters
  };

 
 
  // const placeholderImageUrl = 'https://picsum.photos/50';

  const placeholderImageUrl =  <Noimage height={30}/>
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    }
    return str;
  };
  const fileName = replaceFile && replaceFile?.name || '';
const truncatedFileName = truncateString(fileName, 30);
const handleFolderClick = (folderId, label,permission) => {
 
  // setClickedFolderZusNon({ label: label, id: folderId, permissions: permission  });
  // navigate({
  //   pathname: "/",
  // });
  // setIsAddfolderModal(false)
};


  return (
    <>
      {renameMutation.isLoading && <Loader></Loader>}

      {addFolderMutation.isLoading && <Loader></Loader>}

      {deleteMutation.isLoading && <Loader></Loader>}

      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        // {location.pathname === '/' ?
        //  className='big'
        //  : null
        // }

        className={titleMessage === "Move to folder" ? "big" : ""}
        data-class={titleMessage === "Preview file" ? "preview" : ""}

        // className={
        //   location.pathname === '/' ? 'big' : ''
        // }
      >
        <Box className="modalWrapper">
          <Box className="modalContainer">
            <Typography component="h5" variant="h5" className="modaltitle">
              {titleMessage}
              <Close onClick={onClose} />
            </Typography>

            {deleteAsset ? (
              <>
                <Box className="modalContent">
                  <Typography className="notification">
                    {collectionDeleteModal
                      ? commonMessages?.uSureWannaDeleteThisCollection
                      : `Are you sure you want to remove ${
                          deleteMultiple
                            ? "these assets from collection"
                            : "this asset from collection"
                        }?`}
                  </Typography>
                </Box>
                <Box className="buttonGroup">
                  <Button
                    className="button"
                    variant="filled"
                    onClick={handleCallbackDelete}
                    disabled={mutationDeleteCollectionList?.isLoading}
                  >
                    Delete
                  </Button>
                  {/* <Button onClick={onClose}>No</Button> */}
                </Box>
              </>
            ) : deleteAssetFromCollection ? (
              <>
                {" "}
                <Box className="modalContent">
                  <Typography className="notification">
                    {deleteIdRemoveCollection
                      ? "Are you sure you want to remove this asset from collection?"
                      : "Are you sure you want to remove these assets from collection?"}
                  </Typography>
                </Box>
                <Box className="buttonGroup">
                  <Button
                    className="button"
                    variant="filled"
                    onClick={handleCallbackDelete}
                  >
                    Remove
                  </Button>
                  {/* <Button onClick={onClose}>No</Button> */}
                </Box>
              </>
            ) : deleteFilterInPopUp ? (
              <>
                {" "}
                <Box className="modalContent">
                  <Typography className="notification">
                    Are you sure you want to delete?
                  </Typography>
                </Box>
                <Box className="buttonGroup">
                  <Button
                    className="button"
                    variant="filled"
                    onClick={handleCallbackDeleteFilterInPopUp}
                  >
                    Delete
                  </Button>
                  {/* <Button onClick={onClose}>No</Button> */}
                </Box>
              </>
            ) : deleteFilterChip ? (
              <>
                {" "}
                <Box className="modalContent">
                  <Typography className="notification">
                    Are you sure you want to delete?
                  </Typography>
                </Box>
                <Box className="buttonGroup">
                  <Button
                    className="button"
                    variant="filled"
                    onClick={handleCallbackDeleteFilterChip}
                  >
                    Delete
                  </Button>
                  {/* <Button onClick={onClose}>No</Button> */}
                </Box>
              </>
            ) : deleteAssetFromDetailPage ? (
              <>
                <Box className="modalContent">
                  <Typography className="notification">
                    {commonMessages?.uSureWannaDeleteThisAsset}
                  </Typography>
                </Box>
                <Box className="buttonGroup">
                  <Button
                    className="button"
                    variant="filled"
                    onClick={handleCallbackDelete}
                    disabled={mutationDeleteAsset?.isLoading}
                  >
                    Delete
                  </Button>
                  {/* <Button onClick={onClose}>No</Button> */}
                </Box>
              </>
            ) : deleteAssetFromLandingPage ? (
              <>
                <Box className="modalContent">
                  <Typography className="notification">
                    {deleteMultiple ? (
                      <>Are you sure you want to delete these assets?</>
                    ) : (
                      commonMessages?.uSureWannaDeleteThisAsset
                    )}
                  </Typography>
                </Box>
                <Box className="buttonGroup">
                  <Button
                    className="button"
                    variant="filled"
                    onClick={handleCallbackDelete}
                  >
                    Delete
                  </Button>
                  {/* <Button onClick={onClose}>No</Button> */}
                </Box>
              </>
            ) : isPreviewModalCart ? (
              <>
                <Box className="boxWrapper">
                  {detailPageDatta.elementMimeType == "video/mp4" ? (
                    <video width="800px" height="500px" controls>
                      <source
                        src={detailPageDatta?.videoSource}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : detailPageDatta.elementMimeType?.includes("audio") ? (
                    <Box className="noaudio">
                      <img src={Audio} />
                      <audio controls>
                        <source
                          src={detailPageDatta?.audioSource}
                          type="audio/mpeg"
                        />
                        Your browser does not support the audio element.
                      </audio>
                    </Box>
                  ) : detailPageDatta.elementMimeType !=
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                    detailPageDatta.elementMimeType !=
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                    detailPageDatta.elementMimeType !=
                      "application/vnd.openxmlformats-officedocument.presentationml.presentation" &&
                    detailPageDatta.elementMimeType != "application/pdf" &&
                    detailPageDatta.elementMimeType != "text/csv" &&
                    detailPageDatta.elementMimeType !=
                      "application/vnd.oasis.opendocument.presentation" &&
                    detailPageDatta.elementMimeType !=
                      "application/vnd.ms-powerpoint" ? (
                    detailPageDatta?.elementMimeType?.includes("image") ? (
                      <Box className="popupImage">
                        <img src={fileUrl} />
                      </Box>
                    ) : detailPageDatta?.elementMimeType == "text/plain" ? (
                      <object
                        data={fileUrl + "#toolbar=0"}
                        //type="application/pdf"
                        width={"100%"}
                        height={"100%"}
                        // minWidth={"500px"}
                        className="object"
                      ></object>
                    ) : (
                      <Box className="noimage">
                        <Noimage />
                        <Typography mt={4}>
                          Preview not available for this file format. <br />
                          Please download the file to view its content.
                        </Typography>
                      </Box>
                    )
                  ) : (
                    <iframe
                      height="500"
                      width="800"
                      src={`https://dam-api.pimcoredemos.com/asset/get-preview-document/${detailPageDatta?.elementId}`}
                    ></iframe>
                  )}
                </Box>
              </>
            ) : isPreviewModal ? (
              <>
                {" "}
                {
                  <Box className="boxWrapper">
                    {/* <DocViewer
                      documents={[{ uri: fileUrl }]}
                      pluginRenderers={DocViewerRenderers}
                      config={{
                        header: {
                          disableFileName: true,
                          retainURLParams: false,
                        },
                      }}
                    /> */}
                    {detailPageDatta.mimetype == "video/mp4" ? (
                      <video width="800px" height="500px" controls>
                        <source
                          src={detailPageDatta?.videoSource}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : detailPageDatta.mimetype?.includes("audio") ? (
                      <Box className="noaudio">
                        <img src={Audio} />
                        <audio controls>
                          <source
                            src={detailPageDatta?.audioSource}
                            type="audio/mpeg"
                          />
                          Your browser does not support the audio element.
                        </audio>
                      </Box>
                    ) : detailPageDatta.mimetype !=
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                      detailPageDatta.mimetype !=
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                      detailPageDatta.mimetype !=
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation" &&
                      detailPageDatta.mimetype != "application/pdf" &&
                      detailPageDatta.mimetype != "text/csv" &&
                      detailPageDatta.mimetype !=
                        "application/vnd.oasis.opendocument.presentation" &&
                      detailPageDatta.mimetype !=
                        "application/vnd.ms-powerpoint" ? (
                      detailPageDatta?.mimetype?.includes("image")
                       &&
                      detailPageDatta.mimetype !=
                        "image/vnd.adobe.photoshop"       &&
                        detailPageDatta.mimetype != "image/tiff"
                        
                        ? (
                        <Box className="popupImage">
                          <img src={fileUrl} />
                        </Box>
                      ) : detailPageDatta?.mimetype == "text/plain" ? (
                        <>
                          <object
                            data={fileUrl + "#toolbar=0"}
                            //type="application/pdf"
                            width={"100%"}
                            height={"100%"}
                            // minWidth={"500px"}
                            className="object"
                          ></object>
                        </>
                      ) : 
                      detailPageDatta.mimetype ==
                        "image/vnd.adobe.photoshop" ||  detailPageDatta.mimetype == "image/tiff"  ? (
                        <>
                          {" "}
                          <Box className="noimage">
                            <Noimage />

                            <Typography mt={4}>
                              Preview not available for this file format. <br />
                              Please download the file to view its content.
                            </Typography>
                          </Box>
                        </>
                      ) 
                      
                      : 
                      
                      (
                        <Box className="noimage">
                          <Noimage />

                          <Typography mt={4}>
                            Preview not available for this file format. <br />
                            Please download the file to view its content.
                          </Typography>
                        </Box>
                      )
                    ) : (
                      <>
                        <iframe
                          height="500"
                          width="800"
                          src={`https://dam-api.pimcoredemos.com/asset/get-preview-document/${id}`}
                        ></iframe>
                      </>
                    )}
                  </Box>
                }
              </>
            ) : (
              <>
                {isRenameModal ? (
                  <>
                    <Box className="modalContent">
                      <label>Rename</label>

                      <TextField
                        id="outlined-basic"
                        label=""
                        defaultValue={manageFolderLabel}
                        value={searchValueRenameLanding}
                        onChange={(e) =>
                          handleRenameFolderInputChange(e.target.value)
                        }
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                        error={errorInputCreateFolder} // Set error state
                        inputProps={{ maxLength: 50 }}
                        helperText={
                          errorInputCreateFolder
                            ? "Please enter only alphanumeric characters, underscores and hyphen."
                            : ""
                        }
                      />
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        onClick={handleRenameClick}
                        disabled={
                          errorInputCreateFolder ||
                          !searchValueRenameLanding ||
                          renameMutation?.isLoading
                        }
                      >
                        Rename
                      </Button>
                    </Box>
                  </>
                ) : saveAsNewFilterNameModal ? (
                  <>
                    <Box className="modalContent">
                      {" "}
                      <label>Filter name</label>
                      <TextField
                        id="outlined-basic"
                        label=""
                        value={savePopUpInputBoxVAlue}
                        onChange={(e) => {
                          handleSavePopupFn(e);
                        }}
                        variant="standard"
                      />{" "}
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        onClick={() =>
                          handleCallbackCreateNewFilterNameModal(
                            savePopUpInputBoxVAlue
                          )
                        }
                        disabled={
                          !savePopUpInputBoxVAlue ||
                          saveFilterApiMutation?.isLoading
                        }
                      >
                        Save filter
                      </Button>
                    </Box>{" "}
                  </>
                ) : createNewFilterNameModal ? (
                  <>
                    <Box className="modalContent">
                      <label>Filter name</label>
                      <TextField
                        id="outlined-basic"
                        label=""
                        value={savePopUpInputBoxVAlue}
                        onChange={(e) => {
                          handleSavePopupFn(e);
                        }}
                        variant="standard"
                      />
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        onClick={() =>
                          handleCallbackCreateNewFilterNameModal(
                            savePopUpInputBoxVAlue
                          )
                        }
                        disabled={
                          !savePopUpInputBoxVAlue ||
                          saveFilterApiMutation?.isLoading
                        }
                      >
                        Save filter
                      </Button>
                    </Box>
                  </>
                ) : replaceAsset ? (
                  <>
                    <Box className="modalContent">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "1em",
                          cursor: "pointer",
                        }}
                      >
                        <Dropzone onDrop={handleCallbackOnDrop} maxFiles={1}>
                          {({ getRootProps, getInputProps }) => (
                            <>
                              <Box
                                {...getRootProps()}
                                // style={dropzoneStyle}
                                sx={{
                                  border: "1px dashed var(--border)",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  margin: "1em 0",
                                  width: "100%",
                                  minHeight: "200px",
                                  boxSizing: "border-box",
                                  flexDirection: "column",
                                  fontSize: "var(--fontSizeM)",
                                }}
                              >
                                <input {...getInputProps()} />

                                <p>Drag 'n' drop a file here </p>
                                <p className="padding-sm">OR</p>
                                <p className="button link">
                                  Click to select a file
                                </p>
                              </Box>

                              {/* {file && (
                                <Box className="filePath">
                                  <span>{file.name}</span>
                                  <Button
                                    className="button icon"
                                    startIcon={<Close />}
                                    onClick={removeFile}
                                  ></Button>
                                </Box>
                              )} */}

                              {replaceFile && (
                                <Box
                                  className="dataBox col-12 "
                                  sx={{ marginTop: "1em" }}
                                >
                                  <Typography variant="h6">
                                    Selected file
                                  </Typography>
                                  <Box className="formBox1">
                                    <Typography className="flex" mr={2}>
                                      {getBlobUrl(replaceFile) ? (
                                        <img
                                          src={getBlobUrl(replaceFile)} // Use placeholder if Blob URL is null
                                          // src={URL?.createObjectURL(
                                          //   new Blob([replaceFile], {
                                          //     type: "application/zip",
                                          //   })
                                          // )}
                                          alt={
                                            replaceFile?.name || "file preview"
                                          }
                                          // alt="image"
                                          width="50px"
                                          height="50px"
                                        />
                                      ) : (
                                        <Noimage height={50} />
                                      )}

                                      <TooltipStyle
                                        placement="bottom"
                                        arrow
                                        title={replaceFile?.name}
                                      >
                                        {truncatedFileName}
                                      </TooltipStyle>
                                    </Typography>

                                    <Cross
                                      onClick={() => removeFile()}
                                      cursor={"pointer"}
                                    />
                                  </Box>
                                </Box>
                              )}
                              {/* {previewImage && (
                                <div>
                                  <h4>Preview:</h4>
                                  <img
                                    src={previewImage}
                                    alt="Preview"
                                    style={{ maxWidth: "30%" }}
                                  />
                                </div>
                              )} */}
                            </>
                          )}
                        </Dropzone>
                      </Box>
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        onClick={() => handleCallbackReplaceAsset(replaceFile)}
                        disabled={
                          !replaceFile || replaceAssetFileMutation?.isLoading
                        }
                      >
                        Replace
                      </Button>
                    </Box>
                  </>
                ) : isRenameCollectionModal ? (
                  <>
                    <Box className="modalContent">
                      <label>{commonMessages?.renameCollection} to</label>

                      <TextField
                        id="outlined-basic"
                        label=""
                        value={searchValue}
                        onChange={handleChangeRenameCollectionField}
                        // onChange={(e) => setSearchValue(e.target.value)}
                        // defaultValue={collectionName}
                        variant="standard"
                        inputProps={{ maxLength: 50 }} //
                      />
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        disabled={!searchValue}
                        onClick={() =>
                          handleCallbackRenameCollectionModal(searchValue)
                        }
                      >
                        Update
                      </Button>
                    </Box>
                  </>
                ) : isUploadFileModal ? (
                  <>
                    <Box className="modalContent">
                      <FormControl variant="standard">
                        <label>Add tags</label>
                        <ReactSelect
                          isMulti
                          options={uploadFileTags || []}
                          value={selectedValuesTagsModal}
                          onChange={handleOnChangeTagsModal}
                        />
                      </FormControl>
                      <FormControl variant="standard" sx={{ marginTop: "1em" }}>
                        <label>New Filename</label>
                        <TextField
                          id="outlined-basic"
                          label=""
                          value={inputValueUploadFileModal}
                          onChange={(e) =>
                            handleOnChangeTextField(e.target.value)
                          }
                          // onKeyDown={handleKeyPress}
                          variant="standard"
                        />
                      </FormControl>
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        onClick={() =>
                          handleCallbackUploadFileModal(paramsDataUploadModal)
                        }
                      >
                        Submit
                      </Button>
                    </Box>
                  </>
                ) : addNewCollectionModal ? (
                  <>
                    <Box className="modalContent">
                      <Box>Collection</Box>

                      <TextField
                        inputProps={{ maxLength: 50 }}
                        id="outlined-basic"
                        label=""
                        value={searchValueCreateNewCollection}
                        onChange={(e) => handleOnChangeCreateNewCollection(e)}
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                      />
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        disabled={
                          !searchValueCreateNewCollection ||
                          createNewCollectionMutation?.isLoading
                        }
                        onClick={() =>
                          handleCallbackAddNewCollectionModal(
                            searchValueCreateNewCollection
                          )
                        }
                      >
                        {commonMessages?.createNewCollection}
                      </Button>
                    </Box>
                  </>
                ) : updateProfileModalFlag ? (
                  <>
                    <Box className="modalContent">
                      <label>First name</label>

                      <TextField
                        id="outlined-basic"
                        label=""
                        defaultValue={profileDataFromApi?.firstName}
                        value={searchValue}
                        disabled={!editUserProfile ? true : false}
                        onChange={(e) => setProfileFirstName(e.target.value)}
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                      />

                      <label>Last name</label>

                      <TextField
                        id="outlined-basic"
                        defaultValue={profileDataFromApi?.lastName}
                        label=""
                        value={searchValue}
                        disabled={!editUserProfile ? true : false}
                        onChange={(e) => setProfileLastName(e.target.value)}
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                      />
                      <label>Email</label>

                      <TextField
                        id="outlined-basic"
                        defaultValue={profileDataFromApi?.email}
                        disabled={!editUserProfile ? true : false}
                        label=""
                        value={searchValue}
                        onChange={(e) => setProfileEmail(e.target.value)}
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                      />
                    </Box>
                    {editUserProfile ? (
                      <>
                        <Box className="buttonGroup">
                          <Button
                            className="button"
                            variant="filled"
                            onClick={() => handleCancelUserProfileClick()}
                            // onClick={setEditUserProfile(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="button"
                            variant="filled"
                            onClick={() =>
                              handleCallbackUpdateProfile(
                                profileFirstName,
                                profileLastName,
                                profileEmail
                              )
                            }
                          >
                            Update
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <Box className="buttonGroup">
                        <Button
                          className="button"
                          variant="filled"
                          onClick={() => handleEditUserProfileClick()}
                        >
                          Edit
                        </Button>
                      </Box>
                    )}
                  </>
                ) : bulkEditModal ? (
                  <>
                    <Box className="notification" sx={{ margin: "1em" }}>
                      {commonMessages?.batchMetdataUpdateInstructionMsg}
                    </Box>
                    <Box className="modalContent">
                      <FormControl variant="standard">
                        <label>Add tags</label>
                        <ReactSelect
                          isMulti
                          options={uploadFileTags || []}
                          value={selectedValuesTagsModal}
                          onChange={handleOnChangeTagsModal}
                          className="react-select"
                        />
                      </FormControl>

                      <Box className="dataBox">
                        <Typography variant="h6">Metadata</Typography>
                        <FormControl variant="standard" className="formBox">
                          <input
                            type="text"
                            placeholder="Name"
                            value={newMetaData?.name}
                            onChange={(e) =>
                              handleChangeNewMetaData(e.target.value, "name")
                            }
                            className="textbox"
                          />
                          <ReactSelect
                            options={languageOptions || []}
                            className="selection"
                            styles={customStyles}
                            // menuPosition="fixed"
                            // menuPlacement="bottom"
                            placeholder="Language"
                            // menuIsOpen= {true}

                            // value={newMetaData?.language}
                            onChange={(e) =>
                              handleChangeNewMetaData(e, "language")
                            }
                          />
                          <ReactSelect
                            options={inputOptions || []}
                            className="selection"
                            styles={customStyles}
                            placeholder="Input"
                            // menuIsOpen= {true}
                            onChange={(e) => handleChangeNewMetaData(e, "type")}
                            // value={newMetaData?.type}
                          />

                          <Button
                            size="small"
                            variant="filled"
                            onClick={() => handleAddMetadata()}
                            className="button"
                          >
                            Add
                          </Button>
                        </FormControl>
                        {nameError && (
                          <Box className="error-message">
                            {commonMessages?.namefieldErrorMsg}
                          </Box>
                        )}
                        <TableContainer
                          className="tableBox"
                          sx={{ maxHeight: "200px" }}
                        >
                          <Table aria-label="sticky table" className="table">
                            <TableHead>
                              <TableRow>
                                <TableCell component="th" width="30%">
                                  Name
                                </TableCell>
                                <TableCell component="th" width="30%">
                                  Language
                                </TableCell>
                                <TableCell component="th" width="30%">
                                  Value
                                </TableCell>
                                <TableCell
                                  component="th"
                                  align="center"
                                  width="10%"
                                >
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {metadata?.map((value, ind) => (
                                <TableRow tabIndex={-1}>
                                  <TableCell>
                                    <input
                                      type="text"
                                      placeholder="Name"
                                      className="textbox"
                                      value={value?.name}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          value,
                                          ind,
                                          "name"
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <ReactSelect
                                      defaultValue={
                                        value?.language == "en"
                                          ? { label: "en", value: "en" }
                                          : value?.language == "de"
                                          ? { label: "de", value: "de" }
                                          : value?.language == "fr"
                                          ? { label: "fr", value: "fr" }
                                          : { label: "en", value: "en" }
                                      }
                                      //  className="select"
                                      onChange={(e) =>
                                        handleInputChange(
                                          e?.value,
                                          value,
                                          ind,
                                          "language"
                                        )
                                      }
                                      styles={customStyles}
                                      // menuIsOpen={true}
                                      menuPlacement="auto"
                                      menuPosition="fixed"
                                      options={languageOptions || []}
                                      // value={targetObjectFolder?.assignedTags}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <>
                                      {" "}
                                      {value?.type == "checkbox" ? (
                                        <>
                                          <Checkbox
                                            // value={item.data}
                                            // isChecked={true}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e.target.checked,
                                                value,
                                                ind,
                                                "data"
                                              )
                                            }
                                          />
                                        </>
                                      ) : value?.type == "textarea" ? (
                                        <>
                                          <TextareaAutosize
                                            // size="md"
                                            // name="Size"
                                            maxLength={200}
                                            style={{
                                              maxHeight: "220px", // Limit the maximum height
                                              overflowY: "auto", // Add scrollbars if content exceeds max-height
                                              resize: "vertical", // Disable manual resizing
                                            }}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e.target.value,
                                                value,
                                                ind,
                                                "data"
                                              )
                                            }
                                          />
                                        </>
                                      ) : value?.type == "date" ? (
                                        <>
                                          <DatePicker
                                            showIcon
                                            onFocus={(e) =>
                                              (e.target.readOnly = true)
                                            }
                                            onChange={(date) =>
                                              handleInputChange(
                                                date,
                                                value,
                                                ind,
                                                "data"
                                              )
                                            }
                                            selected={value?.data}
                                            inputMode="none"
                                            showYearDropdown
                                            yearDropdownItemNumber={60}
                                            scrollableYearDropdown
                                          />
                                        </>
                                      ) : (
                                        <input
                                          type="text"
                                          className="textbox"
                                          value={value?.data}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.value,
                                              value,
                                              ind,
                                              "data"
                                            )
                                          }
                                        />
                                      )}
                                    </>
                                  </TableCell>
                                  <TableCell>
                                    <TooltipStyle
                                      placement="bottom"
                                      arrow
                                      title="Delete"
                                    >
                                      <Button
                                        size="small"
                                        className="button icon"
                                        onClick={(e) => {
                                          handleInputChange(
                                            e.target.value,
                                            value,
                                            ind,
                                            "delete"
                                          );
                                        }}
                                      >
                                        <Close className="small" />
                                      </Button>
                                    </TooltipStyle>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        disabled={
                          !isValidBatchMEtadataUpdate() ||
                          mutationBatchUpdate?.isLoading
                        }
                        onClick={() =>
                          handleCallbackBulkEdit(
                            metadata,
                            selectedValuesTagsModal
                          )
                        }
                      >
                        Submit
                      </Button>
                    </Box>
                  </>
                ) : compareVersionModal ? (
                  <>{}</>
                ) : clearCartModal ? (
                  <>
                    {" "}
                    <Box className="modalContent">
                      <Typography className="notification">
                        Are you sure you want to clear cart ?
                      </Typography>
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        disabled={mutationClearUserCart?.isLoading}
                        className="button"
                        variant="filled"
                        onClick={handleCallbackClearCart}
                      >
                        Delete
                      </Button>
                    </Box>
                  </>
                ) : isDeletefolderModal ? (
                  <>
                    <Box className="modalContent">
                      <Typography className="notification">
                        Are you sure you want to delete
                        {
                          <Box
                            component="span"
                            sx={{ display: "inline", marginLeft: "2px" }}
                          >
                            {clickedFolderZusNon?.label}
                          </Box>
                        }{" "}
                        ?
                      </Typography>
                      <Typography className="notification">
                        {commonMessages?.deleteFolderInstruction}
                      </Typography>
                    </Box>
                    {/* <Box className="modalContent">
                      {" "}
                      <Typography className="notification">
                        Deleting this folder means you will be deleting all the
                        content of this folder along with its subfolder(s) if any.{" "}
                      </Typography>
                    </Box> */}

                    <Box className="buttonGroup">
                      <Button
                        disabled={deleteMutation?.isLoading}
                        className="button"
                        variant="filled"
                        onClick={handleDeleteFolderClick}
                      >
                        Delete
                      </Button>
                    </Box>
                  </>
                ) : isAddfolderModal ? (
                  <>
                    <Box className="modalContent">
                      {mutationCreateFolderBreadCrum.isLoading ? (
                        <Skeleton count={1} width={300} height={20} />
                      ) : (
                        <Box mb={1}>
                          <Breadcrumb
                            breadcrumbs={breadCrumbsCreateFolderState}
                            onFolderClick={handleFolderClick}
                          />
                        </Box>
                      )}
                      <label>Name</label>

                      <TextField
                        id="outlined-basic"
                        label=""
                        placeholder="Please enter folder name"
                        value={searchValueCreate}
                        onChange={(e) =>
                          handleCreateFolderInputChange(e.target.value)
                        }
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                        error={errorInputCreateFolder} // Set error state
                        inputProps={{ maxLength: 50 }}
                        helperText={
                          errorInputCreateFolder
                            ? "Please enter only alphanumeric characters, underscores and hyphen."
                            : ""
                        }
                      />
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        onClick={handleAddFolderClick}
                        disabled={
                          errorInputCreateFolder ||
                          !searchValueCreate ||
                          addFolderMutation.isLoading
                        }
                      >
                        Create
                      </Button>
                    </Box>
                  </>
                ) : shareCollectionInitialModal ? (
                  <>
                    <Box
                      className="modalContent scroll"
                      sx={{ width: "500px" }}
                    >
                      <Box className="notification">
                        You are sharing a collection of "
                        <Typography
                          variant="b"
                          sx={{
                            fontSize: "var(--fontSizeM)",
                            fontWeight: "bold",
                          }}
                        >
                          {shareName}
                        </Typography>
                        ".
                      </Box>
                      <FormGroup className="shareAssetForm">
                        <FormControl variant="standard">
                          <label>
                            Share name <span class="required">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Please enter name"
                            className="textbox"
                            onChange={(e) =>
                              handleNameInputChange(e.target.value)
                            }
                            value={inputValueName}
                          />
                        </FormControl>

                        <span className="notification-text">
                          {commonMessages?.maxCharHundred}
                        </span>
                        <FormControl variant="standard">
                          <label>
                            Expiry date <span class="required">*</span>
                          </label>

                          <DatePicker
                            showIcon
                            toggleCalendarOnIconClick
                            onFocus={(e) => (e.target.readOnly = true)}
                            onChange={(date) => handleDateChange(date)}
                            selected={selectedDate}
                            inputMode="none"
                            minDate={tomorrow.toDate()}
                            showYearDropdown
                            yearDropdownItemNumber={60}
                            scrollableYearDropdown
                          />
                        </FormControl>

                        <FormControl variant="standard">
                          <label>Comment</label>
                          <TextareaAutosize
                            maxLength={200}
                            style={{
                              maxHeight: "220px", // Limit the maximum height
                              overflowY: "auto", // Add scrollbars if content exceeds max-height
                              resize: "vertical", // Disable manual resizing
                            }}
                            className="textbox"
                            placeholder="Please enter comment"
                            onChange={(e) =>
                              handleCommentInputChange(e.target.value)
                            }
                            value={inputCommentText}
                          />
                        </FormControl>

                        <span className="notification-text">
                          {commonMessages?.maxCharFivehundred}
                        </span>
                        <Box
                          component="div"
                          className="notification"
                          sx={{ padding: "1em", margin: "1em 0 1.5em 0" }}
                        >
                          {commonMessages?.assetMetadataCheckBoxMessage}
                        </Box>
                        <Box sx={{ padding: "0 0 0 0.5em" }}>
                          <Grid
                            container
                            spacing={1}
                            alignItems="stretch"
                            className="borderBox"
                            mb={2}
                          >
                            <Grid item xs={6} className="boxbg">
                              <Box>
                                <Checkbox
                                  id="Asset"
                                  checked={asset?.type === "asset" || false}
                                  onChange={() =>
                                    handleChangeCartConfigType("asset")
                                  }
                                />{" "}
                                <label htmlFor="Asset">Asset</label>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <label htmlFor="Asset type">Asset type</label>
                              <ReactSelect
                                options={assetOptions || []}
                                isDisabled={!asset?.type}
                                onInputChange={handleInputChangeReactSelect}
                                onChange={(e) => handleChangeFormat(e, "asset")}
                                menuPlacement="top"
                                // value={{
                                //   label: customData?.format,
                                //   value: customData?.format,
                                // }}
                              />
                            </Grid>
                          </Grid>

                          {asset?.format === "custom" && (
                            <Grid
                              container
                              spacing={1}
                              sx={{
                                border: "1px solid var(--bg)",
                                padding: "1em",
                                marginTop: "-1.2em !important",
                                marginBottom: "1em !important",
                              }}
                            >
                              <Grid item xs={6}>
                                <label>Width</label>
                                <Box className="textboxPlaceholder">
                                  <input
                                    className="textbox"
                                    disabled={!asset?.type}
                                    type={customOptions?.setup?.width?.type}
                                    min={customOptions?.setup?.width?.min}
                                    value={customData?.width}
                                    onKeyDown={handleKeyPress}
                                    onChange={(e) =>
                                      handleChangeCustom(
                                        e.target.value,
                                        "width"
                                      )
                                    }
                                  />
                                  <span className="inputlabel">PX</span>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <label>Height</label>
                                <Box className="textboxPlaceholder">
                                  <input
                                    max={10}
                                    disabled={!asset?.type}
                                    onKeyDown={handleKeyPress}
                                    type={customOptions?.setup?.height?.type}
                                    min={customOptions?.setup?.height?.min}
                                    value={customData?.height}
                                    onChange={(e) =>
                                      handleChangeCustom(
                                        e.target.value,
                                        "height"
                                      )
                                    }
                                    className="textbox"
                                  />
                                  <span className="inputlabel">PX</span>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <label>Format</label>

                                <ReactSelect
                                  isDisabled={!asset?.type}
                                  value={{
                                    label: customData?.format,
                                    value: customData?.format,
                                  }}
                                  menuPlacement="auto"
                                  options={
                                    customOptions?.setup?.format?.options || []
                                  }
                                  onInputChange={handleInputChangeReactSelect}
                                  onChange={(e) =>
                                    handleChangeCustom(e, "format")
                                  }
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <label>Quality</label>
                                <input
                                  disabled={!asset?.type}
                                  value={customData?.quality}
                                  type={customOptions?.setup?.quality?.type}
                                  max={customOptions?.setup?.quality?.max}
                                  min={customOptions?.setup?.quality?.min}
                                  className="textbox"
                                  onChange={(e) =>
                                    handleChangeCustom(
                                      e.target.value,
                                      "quality"
                                    )
                                  }
                                />
                              </Grid>
                            </Grid>
                          )}
                          <Grid
                            container
                            spacing={1}
                            alignItems="stretch"
                            className="borderBox"
                          >
                            <Grid item xs={6} className="boxbg">
                              <Box>
                                <Checkbox
                                  id="metadata"
                                  checked={
                                    structuredData?.type === "structuredData" ||
                                    false
                                  }
                                  onChange={() =>
                                    handleChangeCartConfigType("structuredData")
                                  }
                                />{" "}
                                <label htmlFor="metadata">Metadata</label>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <label htmlFor="Metadata type">
                                Metadata type
                              </label>
                              <ReactSelect
                                onInputChange={handleInputChangeReactSelect}
                                options={structuredDataOptions || []}
                                isDisabled={!structuredData?.type}
                                onChange={(e) =>
                                  handleChangeFormat(e, "structuredData")
                                }
                                menuPlacement="top"
                                // value={{
                                //   label: structuredData?.format,
                                //   value: structuredData?.format,
                                // }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </FormGroup>
                    </Box>

                    <Box className="buttonGroup justifyBetween">
                      <Button
                        size="small"
                        variant="filled"
                        startIcon={<Share />}
                        className="button"
                        onClick={() =>
                          handleShareBTnClicked(
                            structuredData,
                            asset,
                            customData
                          )
                        }
                        disabled={!isValidAssetMetadataWithNAme()}
                        // disabled={!inputValueName || !selectedDate}
                      >
                        Share
                      </Button>
                    </Box>
                  </>
                ) : isEditCollectionHistoryModal ? (
                  <>
                    <Box className="modalContent" sx={{ width: "500px" }}>
                      <FormGroup className="shareAssetForm">
                        <FormControl variant="standard">
                          <label>
                            Share name <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Please enter name"
                            className="textbox"
                            onChange={(e) =>
                              handleNameInputChange(e.target.value)
                            }
                            value={inputValueName}
                          />
                        </FormControl>
                        <FormControl variant="standard">
                          <label>
                            Expiry date <span class="required">*</span>
                          </label>

                          <DatePicker
                            placeholderText="Select expiry date"
                            showIcon
                            toggleCalendarOnIconClick
                            onFocus={(e) => (e.target.readOnly = true)}
                            onChange={(date) => handleDateChange(date)}
                            selected={selectedDate}
                            inputMode="none"
                            minDate={tomorrow.toDate()}
                            showYearDropdown
                            yearDropdownItemNumber={60}
                            scrollableYearDropdown
                          />
                        </FormControl>

                        <FormControl variant="standard">
                          <label>Comment</label>
                          <TextareaAutosize
                            maxLength={200}
                            style={{
                              maxHeight: "220px", // Limit the maximum height
                              overflowY: "auto", // Add scrollbars if content exceeds max-height
                              resize: "vertical", // Disable manual resizing
                            }}
                            className="textbox"
                            placeholder="Please enter comment"
                            onChange={(e) =>
                              handleCommentInputChange(e.target.value)
                            }
                            value={inputCommentText}
                          />
                        </FormControl>
                        <Box
                          component="div"
                          className="notification"
                          sx={{ padding: "1em", margin: "0 0 1em -0.5em" }}
                        >
                          {commonMessages?.assetMetadataCheckBoxMessage}
                        </Box>
                        <Grid container spacing={1} mt={1}>
                          <Grid item xs={6}>
                            <Box mb={1}>
                              <Checkbox
                                id="metadata"
                                checked={
                                  structuredData?.type === "structuredData" ||
                                  false
                                }
                                onChange={() =>
                                  handleChangeCartConfigType("structuredData")
                                }
                              />{" "}
                              <label htmlFor="metadata">Metadata</label>
                            </Box>
                            <ReactSelect
                              options={structuredDataOptions || []}
                              isDisabled={!structuredData?.type}
                              onChange={(e) =>
                                handleChangeFormat(e, "structuredData")
                              }
                              onInputChange={handleInputChangeReactSelect}
                              menuPlacement="auto"
                              value={{
                                value: structuredData?.format,
                                label: structuredData?.format,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Box mb={1}>
                              <Checkbox
                                id="Asset"
                                checked={asset?.type === "asset" || false}
                                onChange={() =>
                                  handleChangeCartConfigType("asset")
                                }
                              />{" "}
                              <label htmlFor="Asset">Asset</label>
                            </Box>
                            <ReactSelect
                              options={assetOptions || []}
                              isDisabled={!asset?.type}
                              onInputChange={handleInputChangeReactSelect}
                              onChange={(e) => handleChangeFormat(e, "asset")}
                              menuPlacement="auto"
                              value={{
                                value: asset?.format,
                                label: asset?.format,
                              }}
                            />
                          </Grid>

                          {asset?.format === "custom" && (
                            <Grid
                              item
                              xs={12}
                              mt={2}
                              ml={1}
                              pb={3}
                              sx={{
                                border: "1px solid var(--border)",
                                padding: "1em",
                                // marginTop: "1em !important",
                              }}
                            >
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  <label>Width</label>
                                  <Box className="textboxPlaceholder">
                                    <input
                                      className="textbox"
                                      type={customOptions?.setup?.width?.type}
                                      min={customOptions?.setup?.width?.min}
                                      value={customData?.width}
                                      onKeyDown={handleKeyPress}
                                      onChange={(e) =>
                                        handleChangeCustom(
                                          e.target.value,
                                          "width"
                                        )
                                      }
                                    />
                                    <span className="inputlabel">PX</span>
                                  </Box>
                                </Grid>
                                <Grid item xs={6}>
                                  <label>Height</label>
                                  <Box className="textboxPlaceholder">
                                    <input
                                      // max={100}
                                      type={customOptions?.setup?.height?.type}
                                      min={customOptions?.setup?.height?.min}
                                      onKeyDown={handleKeyPress}
                                      value={customData?.height}
                                      onChange={(e) =>
                                        handleChangeCustom(
                                          e.target.value,
                                          "height"
                                        )
                                      }
                                      className="textbox"
                                    />
                                    <span className="inputlabel">PX</span>
                                  </Box>
                                </Grid>
                                <Grid item xs={6}>
                                  <label>Format</label>

                                  <ReactSelect
                                    value={{
                                      label: customData?.format,
                                      value: customData?.format,
                                    }}
                                    onInputChange={handleInputChangeReactSelect}
                                    menuPlacement="auto"
                                    options={
                                      customOptions?.setup?.format?.options ||
                                      []
                                    }
                                    onChange={(e) =>
                                      handleChangeCustom(e, "format")
                                    }
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <label>Quality</label>
                                  <input
                                    value={customData?.quality}
                                    type={customOptions?.setup?.quality?.type}
                                    max={customOptions?.setup?.quality?.max}
                                    min={customOptions?.setup?.quality?.min}
                                    className="textbox"
                                    onChange={(e) =>
                                      handleChangeCustom(
                                        e.target.value,
                                        "quality"
                                      )
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </FormGroup>
                    </Box>
                    <Box className="buttonGroup justifyBetween">
                      <Button
                        className="button "
                        variant="filled"
                        onClick={() => handleCallbackEditCollectionHistory()}
                      >
                        Save
                      </Button>
                    </Box>
                  </>
                ) : isDeleteCollectionHistoryModal ? (
                  <>
                    <Box className="modalContent">
                      <Typography className="notification">
                        {commonMessages?.uSureWannaDelete}
                      </Typography>
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        disabled={
                          publicShareCollectionDeleteMutation?.isLoading
                        }
                        onClick={() =>
                          handleCallbackDeleteCollectionHistory(
                            deleteCollectionHistoryRowDetail
                          )
                        }
                      >
                        Delete
                      </Button>
                      {/* <Button onClick={onClose}>No</Button> */}
                    </Box>
                  </>
                ) : isDeletePSHistoryModal ? (
                  <>
                    <Box className="modalContent">
                      <Typography className="notification">
                        {commonMessages?.uSureWannaDelete}
                      </Typography>
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        onClick={() =>
                          handleCallbackDeletePSHistory(
                            deletePSHistoryRowDetail
                          )
                        }
                        disabled={publicSharePSDeleteMutation?.isLoading}
                      >
                        Delete
                      </Button>
                      {/* <Button onClick={onClose}>No</Button> */}
                    </Box>
                  </>
                ) : shareCopyToClipboardModalFlag ? (
                  <>
                    <Box className="modalContent" sx={{ width: "500px" }}>
                      <input
                        type="text"
                        className="textbox"
                        value={newUrlLink}
                        readOnly
                      />
                    </Box>
                    <Box className="buttonGroup justifyBetween">
                      <Button
                        className="button "
                        variant="filled"
                        onClick={() => copyToClipboard(newUrlLink)}
                        disabled={copied}
                      >
                        Copy link
                      </Button>

                      {copied ? <span>Link copied to clipboard!</span> : null}
                    </Box>
                  </>
                ) : addToCollectionModalFlag ? (
                  <>
                    <Box className="modalContent">
                      {(!collectionListZusNon ||
                        collectionListZusNon?.length === 0) && (
                        <Box className="notification" mb={2}>
                          {commonMessages?.noCollectionPresent}{" "}
                        </Box>
                      )}
                      <label>Collection</label>
                      <ReactSelect
                        options={collectionListZusNon || []}
                        // value={selectedValues}
                        onChange={handleOnChangeCollection}
                      />
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        onClick={() =>
                          handleCallbackAddToCollection(selectedValues)
                        }
                        disabled={
                          !selectedValues || mutationAddToCollection?.isLoading
                        }
                      >
                        {commonMessages?.addToCollection}
                      </Button>
                    </Box>
                  </>
                ) : downloadCustomModalFlag ? (
                  <>
                    {" "}
                    <Box className="modalContent" sx={{ padding: "2em" }}>
                      <Box
                        component="div"
                        className="notification"
                        sx={{ padding: "1em", margin: "0 0 1em -0.5em" }}
                      >
                        {commonMessages?.assetMetadataCheckBoxMessage}
                      </Box>

                      <Grid
                        container
                        spacing={1}
                        alignItems="stretch"
                        className="borderBox"
                        mb={2}
                        mt={2}
                      >
                        <Grid item xs={6} className="boxbg">
                          <Box>
                            <Checkbox
                              id="Asset"
                              checked={asset?.type === "asset" || false}
                              onChange={() =>
                                handleChangeCartConfigType("asset")
                              }
                            />{" "}
                            <label htmlFor="Asset">Asset</label>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="Asset type">Asset type</label>

                          <ReactSelect
                            options={assetOptions || []}
                            isDisabled={!asset?.type}
                            onChange={(e) => handleChangeFormat(e, "asset")}
                            onInputChange={handleInputChangeReactSelect}
                            value={{
                              value: asset?.format,
                              label: asset?.format,
                            }}
                          />
                        </Grid>
                      </Grid>

                      {asset?.format === "custom" && (
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            border: "1px solid var(--bg)",
                            padding: "1em",
                            marginTop: "-1.2em !important",
                            marginBottom: "2em !important",
                            borderRadius: "0 0 3px 3px",
                            borderTop: "0",

                            "& label": {
                              fontWeight: "600",
                            },

                            "& .textbox": {
                              height: "38px",
                            },
                          }}
                        >
                          <Grid item xs={6}>
                            <label>Width</label>
                            <Box className="textboxPlaceholder">
                              <input
                                onKeyDown={handleKeyPress}
                                className="textbox"
                                disabled={!asset?.type}
                                type={customOptions?.setup?.width?.type}
                                min={customOptions?.setup?.width?.min}
                                value={customData?.width || ""}
                                onChange={(e) =>
                                  handleChangeCustom(e.target.value, "width")
                                }
                              />
                              <span className="inputlabel">PX</span>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <label>Height</label>
                            <Box className="textboxPlaceholder">
                              <input
                                // max={100}
                                step="any"
                                disabled={!asset?.type}
                                onKeyDown={handleKeyPress}
                                type={customOptions?.setup?.height?.type}
                                min={customOptions?.setup?.height?.min}
                                value={customData?.height}
                                onChange={(e) =>
                                  handleChangeCustom(e.target.value, "height")
                                }
                                className="textbox"
                              />
                              <span className="inputlabel">PX</span>
                            </Box>
                          </Grid>
                          <Grid item xs={6} mt={1}>
                            <label>Format</label>

                            <ReactSelect
                              isDisabled={!asset?.type}
                              value={{
                                label: customData?.format,
                                value: customData?.format,
                              }}
                              options={
                                customOptions?.setup?.format?.options || []
                              }
                              onChange={(e) => handleChangeCustom(e, "format")}
                              onInputChange={handleInputChangeReactSelect}
                            />
                          </Grid>
                          <Grid item xs={6} mt={1}>
                            <label>Quality</label>
                            <input
                              value={customData?.quality}
                              disabled={!asset?.type}
                              type={customOptions?.setup?.quality?.type}
                              max={customOptions?.setup?.quality?.max}
                              min={customOptions?.setup?.quality?.min}
                              className="textbox"
                              onChange={(e) =>
                                handleChangeCustom(e.target.value, "quality")
                              }
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Grid
                        container
                        spacing={1}
                        alignItems="stretch"
                        className="borderBox"
                      >
                        <Grid item xs={6} className="boxbg">
                          <Box>
                            <Checkbox
                              id="metadata"
                              checked={
                                structuredData?.type === "structuredData" ||
                                false
                              }
                              onChange={() =>
                                handleChangeCartConfigType("structuredData")
                              }
                            />{" "}
                            <label htmlFor="metadata">Metadata</label>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="Metadata type">Metadata type</label>

                          <ReactSelect
                            options={structuredDataOptions || []}
                            isDisabled={!structuredData?.type}
                            onChange={(e) =>
                              handleChangeFormat(e, "structuredData")
                            }
                            onInputChange={handleInputChangeReactSelect}
                            value={{
                              value: structuredData?.format,
                              label: structuredData?.format,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="buttonGroup">
                      <Button
                        className="button"
                        variant="filled"
                        onClick={() =>
                          handleCallbackDownload(
                            structuredData,
                            asset,
                            customData
                          )
                        }
                        disabled={
                          !isValidAssetMetadata() ||
                          customDownloadApiMutation?.isLoading
                        }
                      >
                        Download
                      </Button>
                    </Box>
                  </>
                ) : addToCartModalFlag || editCartCustomModalFlag ? (
                  <>
                    <Box className="modalContent" sx={{ padding: "2em" }}>
                      <Box
                        component="div"
                        className="notification"
                        sx={{ padding: "1em", margin: "0 0 1em -0.5em" }}
                      >
                        {commonMessages?.assetMetadataCheckBoxMessage}
                      </Box>

                      <Grid
                        container
                        spacing={1}
                        alignItems="stretch"
                        className="borderBox"
                        mb={2}
                        mt={2}
                      >
                        <Grid item xs={6} className="boxbg">
                          <Box>
                            <Checkbox
                              id="Asset"
                              checked={asset?.type === "asset" || false}
                              onChange={() =>
                                handleChangeCartConfigType("asset")
                              }
                            />{" "}
                            <label htmlFor="Asset">Asset</label>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="Asset type">Asset type</label>

                          <ReactSelect
                            options={assetOptions || []}
                            isDisabled={!asset?.type}
                            onChange={(e) => handleChangeFormat(e, "asset")}
                            onInputChange={handleInputChangeReactSelect}
                            value={{
                              value: asset?.format,
                              label: asset?.format,
                            }}
                          />
                        </Grid>
                      </Grid>

                      {asset?.format === "custom" && (
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            border: "1px solid var(--bg)",
                            padding: "1em",
                            marginTop: "-1.2em !important",
                            marginBottom: "2em !important",
                            borderRadius: "0 0 3px 3px",
                            borderTop: "0",

                            "& label": {
                              fontWeight: "600",
                            },

                            "& .textbox": {
                              height: "38px",
                            },
                          }}
                        >
                          <Grid item xs={6}>
                            <label>Width</label>
                            <Box className="textboxPlaceholder">
                              <input
                                className="textbox"
                                disabled={!asset?.type}
                                type={customOptions?.setup?.width?.type}
                                min={customOptions?.setup?.width?.min}
                                onKeyDown={handleKeyPress}
                                value={customData?.width}
                                onChange={(e) =>
                                  handleChangeCustom(e.target.value, "width")
                                }
                              />
                              <span className="inputlabel">PX</span>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <label>Height</label>
                            <Box className="textboxPlaceholder">
                              <input
                                // max={100}
                                disabled={!asset?.type}
                                type={customOptions?.setup?.height?.type}
                                min={customOptions?.setup?.height?.min}
                                value={customData?.height}
                                onKeyDown={handleKeyPress}
                                onChange={(e) =>
                                  handleChangeCustom(e.target.value, "height")
                                }
                                className="textbox"
                              />
                              <span className="inputlabel">PX</span>
                            </Box>
                          </Grid>
                          <Grid item xs={6} mt={1}>
                            <label>Format</label>

                            <ReactSelect
                              value={{
                                label: customData?.format,
                                value: customData?.format,
                              }}
                              options={
                                customOptions?.setup?.format?.options || []
                              }
                              isDisabled={!asset?.type}
                              onChange={(e) => handleChangeCustom(e, "format")}
                              onInputChange={handleInputChangeReactSelect}
                            />
                          </Grid>
                          <Grid item xs={6} mt={1}>
                            <label>Quality</label>
                            <input
                              value={customData?.quality}
                              disabled={!asset?.type}
                              type={customOptions?.setup?.quality?.type}
                              max={customOptions?.setup?.quality?.max}
                              min={customOptions?.setup?.quality?.min}
                              className="textbox"
                              onChange={(e) =>
                                handleChangeCustom(e.target.value, "quality")
                              }
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Grid
                        container
                        spacing={1}
                        alignItems="stretch"
                        className="borderBox"
                      >
                        <Grid item xs={6} className="boxbg">
                          <Box>
                            <Checkbox
                              id="metadata"
                              checked={
                                structuredData?.type === "structuredData" ||
                                false
                              }
                              onChange={() =>
                                handleChangeCartConfigType("structuredData")
                              }
                            />{" "}
                            <label htmlFor="metadata">Metadata</label>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="Metadata type">Metadata type</label>

                          <ReactSelect
                            options={structuredDataOptions || []}
                            isDisabled={!structuredData?.type}
                            onChange={(e) =>
                              handleChangeFormat(e, "structuredData")
                            }
                            onInputChange={handleInputChangeReactSelect}
                            value={{
                              value: structuredData?.format,
                              label: structuredData?.format,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="buttonGroup">
                      {addToCartModalFlag ? (
                        <Button
                          className="button"
                          variant="filled"
                          // disabled={
                          //   ((!structuredData?.type || !asset?.type) &&
                          //     !structuredData?.format) ||
                          //   !asset?.format
                          // }
                          disabled={
                            !isValidAssetMetadata() ||
                            mutationAddToCart?.isLoading
                          }
                          onClick={() =>
                            handleCallbackAddToCart(
                              structuredData,
                              asset,
                              customData
                            )
                          }
                        >
                          {commonMessages?.addToCart}
                        </Button>
                      ) : editCartCustomModalFlag ? (
                        <>
                          {" "}
                          <Button
                            className="button"
                            variant="filled"
                            disabled={
                              (!structuredData?.type ||
                                !structuredData?.format) &&
                              //
                              (!asset?.type || !asset?.format)
                            }
                            onClick={() =>
                              handleCallbackEditCart(
                                structuredData,
                                asset,
                                customData
                              )
                            }
                          >
                            Update
                          </Button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Button
                            className="button"
                            variant="filled"
                            disabled={
                              (!structuredData?.type ||
                                !structuredData?.format) &&
                              //
                              (!asset?.type || !asset?.format)
                            }
                            onClick={() =>
                              handleCallbackDownload(
                                structuredData,
                                asset,
                                customData
                              )
                            }
                          >
                            Download
                          </Button>
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  <Box className="modalContent">
                    <InputLabel id="demo-simple-select-helper-label">
                      Age
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={age}
                      //   label="Age"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Modal>
      {/* {moveTofolderModalFlag && (
        <Drawer open={openDrawer} anchor="right" className="uploadAsset">
          <Typography component="h5" variant="h5" className="drawertitle">
            <Move height={25} />{" "}
            <span className="marginL-xs">Move to folderavavavavaDamModalCompoenent</span>
            <Close className="closed" onClick={() => onClose()} />
          </Typography>
          <Box className="">
            <Grid container>
              <Grid item xs={12} className="manageFolder">
                <FolderTree
                  assetId={assetId}
                  setIsMoveModal={setIsMoveModal}
                  moveTofolderModalFlag={moveTofolderModalFlag}
                  hanldleCallbackMoveToFolder={hanldleCallbackMoveToFolder}
                  handleMoveTofolderCallbackApi={handleMoveTofolderCallbackApi}
                  assetArr={assetArr}
                />
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      )} */}
    </>
  );
};

export default DamPortalModal;
