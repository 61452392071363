


import { Button, ButtonGroup, Grid,Typography, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,} from "@mui/material";
import LeftMenu from "../components/LeftMenu";
import DamHeader from "../components/DamHeader";
import SelectorDetail from "../components/SelectorDetail";
import Footer from "../components/Footer";
import DetailedDescription from "../components/DetailedDescription";
import DetailedImage from "../components/DetailedImage";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { detailPageNavigation, saveTags,detailPageVersion } from "../api/postRequest";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { ReactComponent as Visibility } from '../assest/svg/eye.svg';
import { Box } from "@mui/system";
import {ReactComponent as ChevronBack} from '../assest/svg/chevronLeft.svg';
import { ReactComponent as Save } from "../assest/svg/save.svg";


import { showErrorToast, showSuccessToast } from "../assest/Toaster";
import Select from "react-select";
import DamPortalModal from "../components/DamPortalModal";

import { useNonPersistedStore } from "../store/nonPersistedStore";
import { TooltipStyle } from "../components/RightSectionImages";
import Breadcrumb from "../components/Breadcrumb";
const DetailPage = () => {
  const [detailPageDatta, setDetailPageDatta] = useState();
  const [searchParams] =useSearchParams()
  const [fileUrl, setFileUrl] = useState('');
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  
  const navigate = useNavigate();
const location =useLocation();
const [selectedValues, setSelectedValues] = useState(
  detailPageDatta?.assignedTags
);
const {setVersionDataZusNon,setReplacedImageZusNon  ,clickedFolderZusNon,setClickedFolderZusNon}=useNonPersistedStore();
const handleNavigationBack = () => {
   
  navigate(-1);
};




const handlePreview = () => {

    setIsPreviewModal(true);

    setFileUrl(`https://dam-api.pimcoredemos.com${detailPageDatta?.path}${detailPageDatta?.name}`);
};



const mutation = useMutation((post) => detailPageVersion(post), {
  onSuccess: (result) => {
    if (result) {
      if (result?.data?.success) {
        let output = result.data?.data;
        const newArray = output?.map((obj) => ({
          ...obj,
          ["isChecked"]: false,
        }));

        setVersionDataZusNon(result?.data?.data);
      } else {
      }
    }
  },
  onError: (error) => {},
});


const mutationSaveTags = useMutation(
  (post) => saveTags(post, detailPageDatta?.id),
  {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          mutation.mutate({assetId:detailPageDatta?.id})
          mutationDetailPage.mutate({assetId:searchParams?.get("id")});
          showSuccessToast(result?.data?.message);
        } else {
          showErrorToast(result?.data?.message)
        }
      }
    },
    onError: (error) => {},
  }
);


const mutationDetailPage = useMutation((post) => detailPageNavigation(post), {
  onSuccess: (result) => {
    if (result) {
      if (result.data.success) {
        setDetailPageDatta(result?.data?.data)
      } else {
        
      }
    }
  },
  onError: (error) => {},
});

const callbackSingleAssetApiFn =()=>{
 
  mutationDetailPage.mutate({assetId:searchParams?.get("id")});
}




useEffect(() => {
  mutationDetailPage.mutate({assetId:searchParams?.get("id")});
}, []);
const shouldButtonDisappear = !clickedFolderZusNon?.permissions?.edit_asset;

const handleSaveTags = (id) => {
  const payload = {
    // assetId:detailPageData?.id,
    tags: selectedValues,
  };
  mutationSaveTags.mutate(payload);
};
const handleOnChange = (selectedOptions) => {
  setSelectedValues(selectedOptions);
};
useEffect(() => {
  setSelectedValues(detailPageDatta?.assignedTags);
}, [detailPageDatta?.assignedTags]);

const handleCloseModal = () => {
  setIsPreviewModal(false);
};
const handleCallbackPreview = () => {
 
};

useEffect(()=>{
  return()=>{
    setReplacedImageZusNon("");
  }
},[])

const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
      return str.slice(0, maxLength) + '...';
  }
  return str;
};
const handleFolderClick = (folderId, label,permission) => {
  setClickedFolderZusNon({ label: label, id: folderId, permissions: permission  });
  navigate({
    pathname: "/",
  });
};

const headerName = detailPageDatta &&  detailPageDatta?.headername || '';
const truncatedHeaderName = truncateString(headerName, 50);
useEffect(() => {
  if (detailPageDatta) {
    setFileUrl(`https://dam-api.pimcoredemos.com${detailPageDatta.path}${detailPageDatta.name}`);
  }
}, [detailPageDatta]);

  return (
    <>
      {mutationDetailPage.isLoading && <Loader></Loader>}
      <Box>
        <DamPortalModal
          open={isPreviewModal}
          onClose={handleCloseModal}
          fileUrl={fileUrl}
          titleMessage={"Preview file"}
          isPreviewModal={isPreviewModal}
          detailPageDatta={detailPageDatta}
          handleCallbackPreview={handleCallbackPreview}
        />
      </Box>
      <Grid container className="container bg details">
        <Grid item xs={1} className="menu">
          <LeftMenu />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ minHeight: "calc(100% - 50px)" }}
          className="rightPart"
        >
          <DamHeader />
          <Grid container className="bodyPart">
            {!mutationDetailPage?.isLoading ? (
              detailPageDatta ? (
                <>
                  <Box className="selector ">
                    <Box className="breadcrumb">
                      <Typography
                        component="h2"
                        variant="h5"
                        className="heading"
                      >
                        {/* Asset management detail */}
                        <TooltipStyle
                          placement="bottom"
                          arrow
                          title={detailPageDatta?.headername}
                        >
                          {truncatedHeaderName}
                        </TooltipStyle>
                      </Typography>
                    </Box>
                    
                    <Box className="buttonGroup">
                      <Button
                        size="small"
                        startIcon={<ChevronBack />}
                        className="button"
                        onClick={handleNavigationBack}
                      >
                        Back
                      </Button>

                     {/* <Button
                        size="small"
                        startIcon={<Visibility />}
                        className="button"
                        onClick={handlePreview}
                      >
                        Preview
                      </Button>  */}

                      <SelectorDetail
                        callbackSingleAssetApiFn={callbackSingleAssetApiFn}
                        detailPageDatta={detailPageDatta}
                        id={searchParams?.get("id")}
                      />
                    </Box>
                  </Box>
                      <Box className="breadcrumbWrapper">
                  <Breadcrumb
                      breadcrumbs={detailPageDatta?.breadcrum}
                      onFolderClick={handleFolderClick}
                    />
                    </Box>
                  <Grid item md={6} className="assetDetailLeft">
                  <Box class="dataDescription mt">
                          <Box className="chipset">
                            File name
                            <span>{detailPageDatta?.filename}</span>
                          </Box>
                          <Box className="chipset">
                            Creation date
                            <span>{detailPageDatta?.creationDate}</span>
                          </Box>
                          <Box className="chipset">
                            Modification date
                            <span>{detailPageDatta?.modificationDate}</span>
                          </Box>
                          <Box className="chipset">
                            File size
                            <span>
                              {/* {detailPageDatta?.embeddedMetaData?.FileSize} */}
                              {detailPageDatta?.size} ({" "}
                              {detailPageDatta?.imageHeight}*
                              {detailPageDatta?.imageWidth})
                            </span>
                          </Box>
                          <Box className="chipset">
                            Type
                            <span>{detailPageDatta?.type}</span>
                          </Box>
                          <Box className="chipset">
                            Mime type
                            <span>{detailPageDatta?.mimetype}</span>
                          </Box>
                          
                        </Box>
                    {detailPageDatta && (
                      <>
                       {fileUrl && detailPageDatta && ( // Only render if fileUrl is set
                      <DetailedImage fileUrl={fileUrl} detailPageImg={detailPageDatta} />
                    )}
                        {/* <DetailedImage   fileUrl={fileUrl} detailPageImg={detailPageDatta} /> */}
                        <Box class="dataDescription">
                          <Box className="selectBox">
                            <Box
                              className="flex justifyBetween"
                              mt={3}
                              mb={1}
                            >
                              <Typography>Tags</Typography>

                              {!shouldButtonDisappear && (
                                <Button
                                  className="button "
                                  onClick={handleSaveTags}
                                  disabled={
                                    selectedValues?.length === 0 ||
                                    mutationSaveTags?.isLoading
                                  }
                                  variant="filled"
                                  startIcon={<Save />}
                                >
                                  Save
                                </Button>
                              )}
                            </Box>

                            <Select
                              isMulti
                              options={detailPageDatta?.tags}
                              value={selectedValues}
                              onChange={handleOnChange}
                              menuPlacement="top"
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                  </Grid>
                  <Grid item md={6} className="mL assetDEtailRight">
                    {detailPageDatta && (
                      <>
                        <DetailedDescription
                          callbackSingleAssetApiFn={callbackSingleAssetApiFn}
                          detailPageData={detailPageDatta}
                          mode={searchParams.get("mode")}
                        />
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Box className="notificationWrapper col-12">
                    <Box className="notification">
                      No asset of the given ID found
                    </Box>
                  </Box>
                </>
              )
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}

export default DetailPage