import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import {ReactComponent as Noimage} from '../assest/svg/NoImage.svg';
import {ReactComponent as PdfIcon} from '../assest/svg/pdf.svg';
import {ReactComponent as HtmlIcon} from '../assest/svg/html.svg';
import {ReactComponent as PsdIcon} from '../assest/svg/psd.svg';
import {ReactComponent as CsvIcon} from '../assest/svg/csv.svg';

import {ReactComponent as SvgIcon} from '../assest/svg/svg.svg';
import {ReactComponent as TiffIcon} from '../assest/svg/tiff.svg';
import {ReactComponent as EpsIcon} from '../assest/svg/eps.svg';
import {ReactComponent as Mp4Icon} from '../assest/svg/mp4.svg';
import Audio from '../assest/images/Audio.png'
import { useNonPersistedStore } from "../store/nonPersistedStore";
import { useSearchParams } from 'react-router-dom';

const DetailedImage = ({detailPageImg,  fileUrl}) => {

  const [thumnailReplace,setThumbnialReplace]  = useState();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id')
  const {
    replacedImageZusNon
  } = useNonPersistedStore();

 

  useEffect(() => {
  setTimeout(() => {
    const cacheBuster = new Date().getTime(); 
    setThumbnialReplace(`${detailPageImg?.thumbnail}?t=${cacheBuster}`);
    // setThumbnialReplace(detailPageImg?.thumbnail)
    
  }, 1000);  
    
  
    
  }, [replacedImageZusNon,detailPageImg?.thumbnail])

  
 
  
  
  return (
    <>
      <Box className="boxWrapper">
        {detailPageImg?.mimetype == "video/mp4" ? (
          <Box className="popupImage">
          <video width="800px" height="500px" controls>
            <source src={detailPageImg?.videoSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </Box>
        ) : detailPageImg.mimetype?.includes("audio") ? (
          <Box className="noaudio popupImage">
            <img src={Audio} />
            <audio controls>
              <source src={detailPageImg?.audioSource} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </Box>
        ) : detailPageImg.mimetype !=
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
          detailPageImg.mimetype !=
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          detailPageImg.mimetype !=
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" &&
          detailPageImg.mimetype != "application/pdf" &&
          detailPageImg.mimetype != "text/csv" &&
          detailPageImg.mimetype !=
            "application/vnd.oasis.opendocument.presentation" &&
          detailPageImg.mimetype != "application/vnd.ms-powerpoint" ? (
          detailPageImg?.mimetype?.includes("image") &&
          detailPageImg.mimetype != "image/vnd.adobe.photoshop" &&
          detailPageImg.mimetype != "image/tiff" ? (
            <Box className="popupImage">
              <img src={fileUrl} />
            </Box>
          ) : detailPageImg?.mimetype == "text/plain" ? (
            <Box className="popupImage">
            <object
              data={fileUrl + "#toolbar=0"}
              //type="application/pdf"
              width={"100%"}
              height={"100%"}
              // minWidth={"500px"}
              className="object"
            ></object>
            </Box>
          ) : detailPageImg.mimetype == "image/vnd.adobe.photoshop" ||
          detailPageImg.mimetype == "image/tiff"   ? (
            <>
              {" "}
              <Box className="popupImage">
                <Box className="noimage">
                  <Noimage />

                  <Typography mt={4}>
                    Preview not available for this file format. <br />
                    Please download the file to view its content.
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <Box className="popupImage">
            <Box className="noimage">
              <Noimage />
              <Typography mt={4}>
                Preview not available for this file format. <br />
                Please download the file to view its content.
              </Typography>
            </Box>
            </Box>
          )
        ) :    detailPageImg.mimetype == "text/csv" ? <>  
          <Box className="popupImage">
            <Box className="noimage">
        <Noimage />

        <Typography mt={4}>
          Preview not available for this file format. <br />
          Please download the file to view its content.
        </Typography>
        </Box>
            
      </Box></>: (
          <Box className="popupImage">
         
            <iframe
              height="500"
              width="800"
              src={`https://dam-api.pimcoredemos.com/asset/get-preview-document/${id}`}
            ></iframe>
          </Box>
        )}
      </Box>
      {/* <Box
        sx={{
          padding: "1em",
          "& img": {
            width: "100%",
            objectFit: "contain",
            border: "1px solid var(--border)",
            padding: "5px",
            maxHeight: "200px",
            padding: "1em",
            background: "var(--white)",
          },
        }}
      >
        {detailPageImg?.mimetype.length === 0 ? (
          <>
            <Noimage height={120} className="svgNoImage" />
          </>
        ) : (
          thumnailReplace && (
            <img
              title={detailPageImg?.filename}
              src={`${"https://"}${"dam-api.pimcoredemos.com"}${thumnailReplace}`}
            />
          )
        )}
      </Box> */}
    </>
  );
}

export default DetailedImage