import React, { useEffect, useState } from "react";

import { ReactComponent as Shop } from "../assest/svg/shopicon.svg";
import { ReactComponent as Download } from "../assest/svg/downloadicon.svg";
import { ReactComponent as Edit } from "../assest/svg/editicon.svg";
import { ReactComponent as Move } from "../assest/svg/moveicon.svg";
import { ReactComponent as Delete } from "../assest/svg/deleteicon.svg";
import { ReactComponent as Sort } from "../assest/svg/sort.svg";
import { ReactComponent as More } from "../assest/svg/more.svg";
import { ReactComponent as CardView } from "../assest/svg/cardview.svg";
import { ReactComponent as ListView } from "../assest/svg/listView.svg";
import {ReactComponent as ChevronBack} from '../assest/svg/chevronLeft.svg';
import {ReactComponent as DownArrow} from '../assest/svg/chevronDown.svg';
import {ReactComponent as Cross} from '../assest/svg/cross.svg';
// import { menuStyles, paperPropsStyles } from "../styledComponents/MenuStyles.js"; 
import { menuStyles, paperPropsStyles } from "../styledComponents/MenuStyles"; 
import { menuStylesThreeDots, paperPropsStylesThreeDotsLAndingPAge } from "../styledComponents/MenuStylesThreeDotsCardLandingPage"; 

import {
  Box,
  Button,
  Menu,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import { useStore } from "../store/persistedStore";
import { useNonPersistedStore } from "../store/nonPersistedStore";
import DamHeader from "../components/DamHeader";
import LeftMenu from "../components/LeftMenu";
import {
  addToCart,
  customDownloadApi,
  deleteAsset,
  deleteCollectionDetailAsset,
  downloadSelectedAssets,
  getCollectionDetail,
  getCollectionPublicShareList,
  getPublicShareList,
  getUserCart,
  publicShareCollectionDownload,
  publicShareDownload,
} from "../api/postRequest";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import Selector from "../components/Selector";
import PaginationComponent from "../components/PaginationComponent";
import DamPortalModal from "../components/DamPortalModal";
import {ReactComponent as RightArrow} from '../assest/svg/arrowRight.svg';
import { showErrorToast, showSuccessToast } from "../assest/Toaster";
import { truncate } from "../components/FolderTree";



import {withStyles} from "@material-ui/core/styles";
import commonMessages from "../utils/commomMessages";

const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);

const PublicShareAssetPage = () => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [gridView, setIsGridView] = useState(true);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [loopMenuPopId, setLoopMenuPopId] = useState(null);
  const navigate = useNavigate();
  const [anchorElOption, setAnchorElOption] = useState(null);
  const openOption = Boolean(anchorElOption);
  const [anchorEl, setAnchorEl] = useState(null);
  const [optionBox, setOptionBox] = useState(false);
  let publicShareParamsId = useParams();

 

  const {
    setMainPageListingArr,

    setTotalCountCollectionZustand,
    clickedCollectionRowZus,
  } = useStore();
  const {
    publicShareListinZusNon,
    setPublicShareListinZusNon,
    publicShareCompleteListinZusNon,
    setPublicShareCompleteListinZusNon,
    collectionDetailListingZusNon,
    setCollectionDetailListingZusNon,
    setCollectionDetailListingTotalCountZusNon,
    collectionDetailListingTotalCountZusNon,
    setUserCartListingZusNon,setUserCartListingTotalCountZusNon,
    setPublicShareTotalCountZusNon,
    publicShareTotalCountZusNon,
  } = useNonPersistedStore();


           

  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteId, setDeleteId] = useState();
  const [onlyChecked, setOnlyChecked] = useState();
  const [downloadCustomModal, setDownloadCustomModal] = useState(false);
  const [asset, setAsset] = useState();
  const [errorMessage, setErrorMessage] = useState();
  
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [sortingCollectionDetail, setSortingCollectionDetail] = useState();
  const pageSize = 12; //
  const openSort = Boolean(anchorElSort);

  const open = Boolean(anchorEl);

  const mutationGetCollectionDetail = useMutation(
    (post) => getCollectionDetail(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            let output = result?.data?.data?.mappedAssets;

            const newArray = output?.map((obj) => ({
              ...obj,
              ["isChecked"]: false,
            }));

            setCollectionDetailListingZusNon(newArray);
            setTotalCountCollectionZustand(result.data?.data?.totalResults);
            //   setImageArray(newArray);
            //   setTotalCount(result.data?.data?.totalResults);
            setCollectionDetailListingTotalCountZusNon(
              result?.data?.data?.totalResults
            );
          } else {
            setCollectionDetailListingZusNon(null);
          }
        }
      },
      onError: (error) => {},
    }
  );
  const mutationGetPublicShareList = useMutation(
    ({post,payload})=> getPublicShareList(post,payload),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            let output = result?.data?.data?.items;
          
            const newArray = output?.map((obj) => ({
              ...obj,
              ["isChecked"]: false,
            }));
            setPublicShareCompleteListinZusNon(result?.data?.data);
           
            setPublicShareListinZusNon(newArray);

            setPublicShareTotalCountZusNon(result?.data?.data?.totalResults);
          } else {
            setPublicShareListinZusNon(null);
            setErrorMessage(result.data?.message)
          }
        }
      },
      onError: (error) => {},
    }
  );
  const mutationGetCollectionPublicShareList = useMutation(
    ({post,payload}) => getCollectionPublicShareList(post,payload),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            let output = result?.data?.data?.items;
         
            const newArray = output?.map((obj) => ({
              ...obj,
              ["isChecked"]: false,
            }));
           

            setPublicShareListinZusNon(newArray);
            setPublicShareCompleteListinZusNon(result?.data?.data);
            
           
            setPublicShareTotalCountZusNon(
              result?.data?.data?.totalResults
            );
          
          } else {
            setPublicShareListinZusNon(null)
            setErrorMessage(result.data?.message)
          }
        }
      },
      onError: (error) => {},
    }
  );
 
  
  
  useEffect(() => {
    console.log("publicShareParamsIdd",publicShareParamsId?.id);
    if(publicShareParamsId?.id){
      mutationGetPublicShareList.mutate({post:publicShareParamsId?.id})
    }

    if(publicShareParamsId?.collectionSId){
      let payload = {
        currentPage: currentPage,
        collectionId: publicShareParamsId?.collectionSId,
      };
      
      mutationGetCollectionPublicShareList.mutate(  {  post: publicShareParamsId?.collectionSId, payload})
    }
    
  
  }, [])
  
  const handleListView = () => {
    setIsGridView(false);
    // setIsListView(true);
  };
  const handleChecked = (e, idx) => {
    if (!e.target.checked) {
      setIsAllChecked(false);
    }

    let imgCollection = publicShareListinZusNon?.map((item) => {
      if (item?.id === idx) {
        item.isChecked = !item.isChecked;
      }
      return item;
    });
   

    const countIsCheckedTrue = imgCollection?.reduce(
      (acc, obj) => (obj?.isChecked ? acc + 1 : acc),
      0
    );

    if (imgCollection?.length == countIsCheckedTrue) {
      setIsAllChecked(true);
    }
    if (imgCollection?.length > 1) {
      setOptionBox(true);
    } else {
      setOptionBox(false);
    }
    let onlyIsChecked = imgCollection.filter((item, idx) => {
      if (item?.isChecked) {
        return item;
      }
    });
    const newArrayOnlyIsChecked = onlyIsChecked?.map(obj => ({
      dataPoolId: obj.dataPoolId,
      elementId: obj.id
  }));
   setOnlyChecked(newArrayOnlyIsChecked)
        
    
    

     
    setCollectionDetailListingZusNon(imgCollection);
  };
  useEffect(() => {
    let payload = {
      collectionId: searchParams?.get("id"),
    };
   
    mutationGetCollectionDetail.mutate(payload);
  }, []);

  useEffect(() => {
    if (downloadCustomModal === false) {
      setAsset();
    }
  }, [downloadCustomModal]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
 
  const handleCheckAll = () => {
 
    let flag = !isAllChecked;
    setIsAllChecked(flag);

    let imgCollection = publicShareListinZusNon?.map((item) => {
      item.isChecked = flag;
      return item;
    });
    setOptionBox(true);
    setPublicShareListinZusNon(imgCollection)
    
  };
  


  const handleGridView = () => {
    setIsGridView(true);
  };
  const handlePageChange = (newPage) => {
    if (newPage === 0) {
      return;
    } else {
      setIsAllChecked(false);
      setCurrentPage(newPage);

      let payload = {
        currentPage: newPage,
        collectionId: searchParams?.get("id"),
      };

      if(publicShareParamsId?.collectionSId){
        let payload = {
          currentPage: newPage,
          collectionId: publicShareParamsId?.collectionSId,
        };
  
      
        mutationGetCollectionPublicShareList.mutate({post:publicShareParamsId?.collectionSId,payload})
      }

      if(publicShareParamsId?.id){
        let payload = {
          currentPage: newPage,
          collectionId: publicShareParamsId?.id,
        };
        mutationGetPublicShareList.mutate({post:publicShareParamsId?.id,payload});
    
      }
  

      
     
    }
  };
  const handleNavigationBack = () => {
    navigate(-1);
    setCollectionDetailListingZusNon(null)
  };

  const handleCloseModal = () => {
    setIsDeleteModal(false);
    setDownloadCustomModal(false)
  };



  const getSelectedIds = (arr) => {
    let selectedIds = arr && arr?.reduce((acc, curr) => {
      if (curr?.isChecked) {
        acc.push(curr?.id);
      }
      return acc;
    }, []);
    return selectedIds || [];
  };

  const customDownloadApiMutation = useMutation(
    (post) => customDownloadApi(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            window.open(
              result?.data?.url,
              "_self"
              // "_blank"
            );

            handleCloseModal();
            showSuccessToast(result.data?.message);
          } else {
            showErrorToast(result.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const mutationDeleteAssetFromCollection = useMutation((post) => deleteCollectionDetailAsset(post,clickedCollectionRowZus?.value), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          showSuccessToast(result?.data?.message);
          handleCloseModal();
          let payload = {
            collectionId: searchParams?.get("id"),
          };
          mutationGetCollectionDetail.mutate(payload);
        } else {
          showErrorToast("error occured");
        }
      }
    },
    onError: (error) => {},
  });

  const mutationpublicShareDownload = useMutation((post) => publicShareDownload(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
           window.open(`${result?.data?.url}`);
        
         
        } else {
          showErrorToast(result?.data?.message);
        }
      }
    },
    onError: (error) => {},
  });


  const mutationpublicShareCollectionDownload = useMutation((post) => publicShareCollectionDownload(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
           window.open(`${result?.data?.url}`);
        
         
        } else {
          showErrorToast(result?.data?.message);
        }
      }
    },
    onError: (error) => {},
  });
  

 

  const handleDownload = (id, parentId, name) => {
    
    // setDownloadCustomModal(true);
    if (id) {
      setAsset({ id: id, parentId: parentId });

      if (publicShareParamsId?.id && id) {
        let payload = {
          shareId: publicShareParamsId?.id,
          selectedAssetId: JSON.stringify([id]),
        };
        mutationpublicShareDownload.mutate(payload);
      }

      if (publicShareParamsId?.collectionSId && id) {
        let payload = {
          shareId: publicShareParamsId?.collectionSId,
          selectedAssetId: JSON.stringify([id]),
        };

        mutationpublicShareCollectionDownload.mutate(payload);
      }
    }

    if (getSelectedIds(publicShareListinZusNon)?.length > 0 && !id) {
      if (publicShareParamsId?.id) {
        let payload = {
          shareId: publicShareParamsId?.id,
          selectedAssetId: JSON.stringify(
            getSelectedIds(publicShareListinZusNon)
          ),
        };
        mutationpublicShareDownload.mutate(payload);
      }

      if (publicShareParamsId?.collectionSId) {
        let payload = {
          shareId: publicShareParamsId?.collectionSId,
          selectedAssetId: JSON.stringify(
            getSelectedIds(publicShareListinZusNon)
          ),
        };
        mutationpublicShareCollectionDownload.mutate(payload);
      }
    }
  };

  const mutationDownloadSelected = useMutation(
    (post) => downloadSelectedAssets(post),
    {
      onSuccess: (result) => {
        if (result?.data?.success && result?.data?.url) {
          window.open(`${result?.data?.url}`);
        } else {
          showErrorToast(result.data?.message);
        }
      },
      onError: (error) => {},
    }
  );
 
  
  const handleDownloadAll = () => {
    

    const idsArray =
      publicShareListinZusNon &&
      publicShareListinZusNon?.map((item) => item.id);

    if (publicShareParamsId?.collectionSId) {
      let payload = {
        shareId: publicShareParamsId?.collectionSId,
        selectedAssetId: JSON.stringify(idsArray),
      };

      mutationpublicShareCollectionDownload.mutate(payload);
    }
    if (publicShareParamsId?.id) {
      let payload = {
        shareId: publicShareParamsId?.id,
        selectedAssetId: JSON.stringify(idsArray),
      };

      mutationpublicShareDownload.mutate(payload);
    }
  };

  const handleDownloadSelected = () => {
    const selectedItems = collectionDetailListingZusNon?.filter(
      (obj) => obj?.isChecked == true
    );
    let selectedIds = [];
    selectedItems.forEach((element) => {
      selectedIds.push(element?.id);
    });
    let payload = { assetIds: selectedIds.toString() };
    mutationDownloadSelected.mutate(payload);
  };

  const mutationGetUserCart = useMutation((post) => getUserCart(post), {
    onSuccess: (result) => {
      if (result?.data?.success) {
        setUserCartListingZusNon(result?.data?.data?.entries);
        setUserCartListingTotalCountZusNon(result?.data?.data?.totalResults)
      } else {
      }
    },
    onError: (error) => {},
  });

 

 
  const handleCloseOption = () => {
    setAnchorElOption(null);
  };

  const handleCrossOverlay = () => {
    setOptionBox(false);
    let imgCollection = publicShareListinZusNon?.map((item) => {
      item.isChecked = false;
      return item;
    });
    setIsAllChecked(false);
  };

  const handleClickOption = (event, obj) => {
    setLoopMenuPopId(obj);
    setAnchorElOption(event.currentTarget);
  };
  const handleNavigation = (id) => {
    navigate({
      pathname: "/detailPage",
      search: `id=${id}`,
    });
  };

  const handleSingleDownload = (assetObj, structuredData, assetData, customData) => {
   
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    let payload = {
      assets: [
        {
          dataPoolId: assetObj?.parentId,
          selectedId: assetObj?.id,
          // configs: configArr,
        },
      ],
    };
    customDownloadApiMutation.mutate(payload);
  };

  const handleBulkDownload = (structuredData, assetData, customData) => {
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    const selectedItems =   publicShareListinZusNon?.reduce((acc, curr) => {
      if (curr?.isChecked === true) {
        acc?.push({
          dataPoolId: curr?.dataPoolId,
          selectedId: curr?.id,
          // configs: configArr,
        });
      }
      return acc;
    }, []);

    let payload = {
      assets: selectedItems,
    };
  
    customDownloadApiMutation.mutate(payload);
  };


  const handleCallbackDownload = (structuredData, assetData, customData) => {
    if (asset?.id) {
      if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);

        return;
      } else if (
        (!structuredData.type && !assetData.format) ||
        (structuredData.type && !structuredData.format) ||
        (assetData.type && !assetData.format)
      ) {
        // Handle validation error, display message to user or prevent API call

        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);

        return;
      }
      handleSingleDownload(asset, structuredData, assetData, customData);
    } else {
      if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
      
        return;
      } else if (
        (!structuredData.type && !assetData.format) ||
        (structuredData.type && !structuredData.format) ||
        (assetData.type && !assetData.format)
      ) {
        // Handle validation error, display message to user or prevent API call
  
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
     
        
        return;
      }
      handleBulkDownload(structuredData, assetData, customData);
    }
  };

  const handleConfigs = ( configs) => {
   
    if (!Array.isArray(configs) || configs.length === 0) {
      return null; // or return an empty fragment, or some placeholder content
    }
    if (configs?.length > 1) {
      return configs?.map((item, idx) => {
        return (
          <Box>
            
            {configs[idx]?.type === "structuredData"
              ? "Metadata"
              : "Asset"}: {configs[idx]?.format}
            {idx !== configs?.length - 1 && ""}
          </Box>
        );
      });
    } else {
      return (
        <Box>
          {configs[0]?.type === "structuredData" ? "Metadata" : "Asset"}: {configs[0]?.format}
        </Box>
      );
    }
  };




  return (
    <>
      {mutationGetPublicShareList?.isLoading && <Loader></Loader>}
      {mutationDeleteAssetFromCollection.isLoading && <Loader></Loader>}
      {mutationGetCollectionPublicShareList.isLoading && <Loader></Loader>}

      <Box>
        <DamPortalModal
          open={downloadCustomModal}
          downloadCustomModalFlag={downloadCustomModal}
          onClose={handleCloseModal}
          titleMessage={"Download"}
          handleCallbackDownload={handleCallbackDownload}
        />
      </Box>

      <Grid container className="container bg publicShare">
        {/* <Grid item xs={1} className="menu">
          <LeftMenu />
        </Grid> */}
        <Grid
          item
          xs={12}
          sx={{ minHeight: "calc(100% - 50px)" }}
          // className="rightPart"
        >
          <DamHeader />
          <Box className="collectionMenu">
            <Grid container>
              <Grid item xs={6}>
                <Typography component="h2" variant="h5" className="heading">
                  {publicShareCompleteListinZusNon?.headerName}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              ></Grid>
              {publicShareListinZusNon && (
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {/* <Box className={`checkbox ${isAllChecked ? "checked" : ""}`}>
                    <Checkbox
                      checked={isAllChecked}
                      name="select all"
                      onClick={handleCheckAll}
                      sx={{ marginLeft: "0" }}
                    />
                    <Typography component="label">Select all</Typography>
                  </Box> */}
                </Grid>
              )}
              {publicShareListinZusNon && (
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <Button
                    size="small"
                    className="button"
                    onClick={() => handleDownloadAll()}
                    variant="filled"
                  
                  >
                    Download All
                  </Button>
                   <Button
                    size="small"
                    className="button"
                    onClick={() => handleDownload()}
                     disabled={getSelectedIds(publicShareListinZusNon)?.length < 1}
                   
                    variant="filled"
                  
                  >
                    Download selected
                  </Button>

                  <TooltipStyle placement="bottom" arrow title="Grid view">
                    <Button
                      sx={{ cursor: "pointer", marginRight: "2px !important" }}
                      onClick={handleGridView}
                      size="small"
                      className={`button icon ${gridView ? "active" : ""}`}
                    >
                      <CardView />
                    </Button>
                  </TooltipStyle>
                  <TooltipStyle placement="bottom" arrow title="List view">
                    <Button
                      size="small"
                      sx={{ cursor: "pointer" }}
                      onClick={handleListView}
                      className={`button icon ${!gridView ? "active" : ""}`}
                    >
                      <ListView />
                    </Button>
                  </TooltipStyle>
                </Grid>
              )}
            </Grid>
            {!publicShareListinZusNon && (
              <Box className="notification">{errorMessage}</Box>
            )}
          </Box>

          {gridView ? (
            <Box className="bodyParts">
              <>
                {publicShareListinZusNon && (
                  <Box className="notification">
                    The assets will be downloaded with following configuration -
                    {handleConfigs(publicShareCompleteListinZusNon?.config)}
                  </Box>
                )}
              </>
              <div className="box detailed">
                {publicShareListinZusNon &&
                  publicShareListinZusNon?.map((imgObj) => (
                    <>
                      <div className="item" key={imgObj?.id}>
                        <Card
                          className={`card ${
                            isAllChecked || imgObj?.isChecked ? "checked" : ""
                          }`}
                        >
                          <Box className="cardMedia">
                            <CardMedia
                              sx={{
                                height: 180,
                                backgroundSize: "contain",
                                cursor: "pointer",
                              }}
                              image={imgObj?.thumbnail}
                              title={imgObj?.name}
                              onClick={() => {
                                handleNavigation(imgObj?.id);
                              }}
                            />
                          </Box>
                          <CardContent
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ width: "80%", display: "flex" }}>
                              <Checkbox
                                checked={imgObj?.isChecked}
                                // value={imgObj?.isChecked}
                                id={imgObj?.id}
                                onClick={(e) => handleChecked(e, imgObj?.id)}
                              />
                              <TooltipStyle
                                placement="bottom"
                                arrow
                                title={
                                  imgObj?.name?.charAt(0)?.toUpperCase() +
                                  imgObj?.name?.slice(1)
                                }
                              >
                                <label htmlFor={imgObj?.id}>
                                  {truncate(
                                    imgObj?.name.charAt(0).toUpperCase() +
                                      imgObj?.name.slice(1),
                                    35
                                  )}
                                </label>
                              </TooltipStyle>
                            </Box>
                            <Button
                              onClick={(e) => handleClickOption(e, imgObj)}
                              size="small"
                              sx={{ marginRight: "5px !important" }}
                              aria-controls={openOption ? "Option" : undefined}
                              aria-haspopup="true"
                              aria-expanded={openOption ? "true" : undefined}
                              className="button icon"
                            >
                              <More />
                            </Button>
                            <Menu
                              anchorEl={anchorElOption}
                              id="Option"
                              open={openOption}
                              onClose={handleCloseOption}
                              onClick={handleCloseOption}
                              // {...paperPropsStyles}
                              {...paperPropsStylesThreeDotsLAndingPAge}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <MenuItem
                                onClick={() =>
                                  handleDownload(
                                    loopMenuPopId?.id,
                                    loopMenuPopId?.dataPoolId
                                  )
                                }
                              >
                                <Download />
                                Download
                              </MenuItem>

                              {/* <MenuItem
                                onClick={() => {
                                  handleDeleteAsset(loopMenuPopId);
                                }}
                              >
                                <Delete />
                                Delete Asset
                              </MenuItem> */}
                            </Menu>
                          </CardContent>
                        </Card>
                      </div>
                    </>
                  ))}
              </div>
            </Box>
          ) : (
            publicShareListinZusNon?.length && (
              <Box className="bodyParts">
                   {publicShareListinZusNon && (
                  <Box className="notification">
                    The assets will be downloaded with following configuration -
                    {handleConfigs(publicShareCompleteListinZusNon?.config)}
                  </Box>
                )}
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan="2" width="10%"></TableCell>

                        <TableCell component="th" width="40%">
                          Name
                        </TableCell>
                        <TableCell component="th" width="15%">
                          Mime type
                        </TableCell>
                        <TableCell component="th" width="15%">
                          Size
                        </TableCell>
                        <TableCell component="th" width="20%" align="center">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& .button": {
                          border: 0,
                          margin: "2px !important",
                        },
                        "& img": {
                          height: "60px",
                          maxWidth: "60px",
                          objectFit: "cover",
                        },
                      }}
                    >
                      {publicShareListinZusNon &&
                        publicShareListinZusNon?.map((imgObj) => (
                          <TableRow
                            tabIndex={-1}
                            className={`${
                              isAllChecked || imgObj?.isChecked ? "checked" : ""
                            }`}
                          >
                            <TableCell>
                              <Checkbox
                                checked={imgObj?.isChecked}
                                // value={imgObj?.isChecked}
                                onClick={(e) => handleChecked(e, imgObj?.id)}
                              />
                            </TableCell>
                            <TableCell key={imgObj?.id}>
                              <img
                                src={imgObj?.thumbnail}
                                title={imgObj?.name}
                              />
                            </TableCell>
                            <TableCell
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                handleNavigation(imgObj?.id);
                              }}
                            >
                              <Typography variant="p">
                                {imgObj?.name.charAt(0).toUpperCase() +
                                  imgObj?.name.slice(1)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="p">
                                {imgObj?.mimetype}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="p">
                                {imgObj?.elementsize}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Box className="buttonGroup">
                                <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title="Download"
                                >
                                  <Button
                                    size="small"
                                    className="button icon"
                                    onClick={() => handleDownload(imgObj?.id)}
                                  >
                                    <Download />
                                  </Button>
                                </TooltipStyle>
                                {/* 
                                <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title="Delete"
                                >
                                  <Button
                                    size="small"
                                    className="button icon"
                                    onClick={() => {
                                      handleDeleteAsset(imgObj);
                                    }}
                                  >
                                    <Delete />
                                  </Button>
                                </TooltipStyle> */}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* <Box sx={{ textAlign: "right", padding: "0 1em" }}>
                  <PaginationComponent
                    currentPage={currentPage}
                    totalCount={collectionDetailListingTotalCountZusNon}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    // itemsPerPage={itemsPerPage}
                  />
                </Box> */}
              </Box>
            )
          )}

          {/* {getSelectedIds(publicShareListinZusNon)?.length > 1 && optionBox && (
            <Box className="selected small">
              <Box className="selectedOne">
                <>
                  <span>
                    {publicShareListinZusNon &&
                      publicShareListinZusNon?.filter(
                        (val) => val?.isChecked === true
                      )?.length}{" "}
                  </span>
                  {getSelectedIds(publicShareListinZusNon)?.length > 1
                    ? "Assets"
                    : "Asset"}{" "}
                  selected
                </>
              </Box>

              <Button
                className="button link"
                // onClick={() => handleDownloadSelected()}
                onClick={() => handleDownload()}
              >
                <Download />
                Download
              </Button>

              <Button
                className="button link"
                onClick={() => handleCrossOverlay()}
              >
                <Cross />
                Clear all
              </Button>
            </Box>
          )} */}
        </Grid>

        <Grid xs={12}>
          <Box sx={{ marginBottom: "20px", position: "relative" }}>
            <PaginationComponent
              currentPage={currentPage}
              totalCount={publicShareTotalCountZusNon}
              pageSize={pageSize}
              onPageChange={handlePageChange}
              itemCount={publicShareListinZusNon?.length || 0}
              // itemsPerPage={itemsPerPage}
            />
          </Box>

          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default PublicShareAssetPage;
