import axios from "axios";
import apiEndpoints from "../api/apiEndpoints";
import { API_URL } from "../config/index";
import { AUTH_TOKEN_REACT, getAuthToken } from "../config/constants";
const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Methods': 'POST',
  'Access-Control-Allow-Headers': 'Content-Type',
  //  "Authorization":AUTH_TOKEN_REACT,
  "Authorization":getAuthToken(),

}
export async function fetchPosts() {
  const response = await fetch(`${API_URL}${apiEndpoints.GET_FEATURED_PRODUCTS}`);
  return response.json()
}

export async function loginToken(data) {
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.GET_LOGIN_TOKEN}`, data, { headers: headers })
}
export async function getLoginToken(data) {

  const headers = {
    // 'Authorization': `Bearer ${token}`,
    //  "Authorization":AUTH_TOKEN_REACT,
    "Authorization":getAuthToken(),
    'Content-Type': 'multipart/form-data',
  }
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.GET_LOGIN_API_TOKEN}`, data, { headers: headers })
}
export async function updateUserProfile(data) {

  const headers = {
    // 'Authorization': `Bearer ${token}`,
    //  "Authorization":AUTH_TOKEN_REACT,
    "Authorization":getAuthToken(),
    'Content-Type': 'multipart/form-data',
   
  }
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.UPDATE_USER_PROFILE}`, data, { headers: headers })
}


export async function getFirstPageListing(data) {

  const headers = {
    // 'Authorization': `Bearer ${token}`,
     "Authorization":getAuthToken(),
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.GET_FIRSTPAGE_LISTING}`,data, { headers: headers })
}
export async function moveToFolder(data) {
  
  const headers = {
    // 'Authorization': `Bearer ${token}`,
   "Authorization":getAuthToken(),
    // 'Content-Type': 'application/x-www-form-urlencoded',
    'Content-Type': 'multipart/form-data',
  }
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.MOVE_TO_FOLDER}`,data, { headers: headers })
}

export async function detailPageNavigation(data) {
  
  const headers = {
    // 'Authorization': `Bearer ${token}`,
    // "Authorization":AUTH_TOKEN_REACT,
    "Authorization":getAuthToken(),
    'Content-Type': 'application/x-www-form-urlencoded',
  }
 
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.GET_DETAILPAGE_SINGLEASSET}`,data,{ headers: headers })
}

export async function detailPageVersion(data) {
  const headers = {
   "Authorization":getAuthToken(),
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.VERSION_ONDETAIL_PAGE}`,data,{ headers: headers }
  );
}

export async function compareVersions(data) {
  const headers = {
    "Authorization":getAuthToken(),
    'Content-Type': 'multipart/form-data',
    // 'Content-Type': 'application/x-www-form-urlencoded',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.COMPARE_VERSIONS}`,data,{ headers: headers }
  );
}

export async function publishOtherVersion(data) {
  const headers = {
     "Authorization":getAuthToken(),
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.PUBLISH_OTHER_VERSION}`,data,{ headers: headers }
  );
}

export async function uploadAssetFromUrl(data) {
  const headers = {
    "Authorization":getAuthToken(),
    'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPLOAD_ASSET_FROM_URL}`,data,{ headers: headers }
  );
}
export async function uploadZip(data) {
 
  const headers = {
     "Authorization":getAuthToken(),
    'Content-Type': 'multipart/form-data',
    // 'Content-Type': 'application/x-www-form-urlencoded',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPLOAD_ZIP}`,data,{ headers: headers }
  );
}


export async function saveTags(data,assetId) {
  const headers = {
     "Authorization":getAuthToken()
    //  'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.SAVE_TAGS}?assetId=${assetId}`,data,{ headers: headers }
  );
}

export async function getCollectionModalTags(data) {
  const headers = {
    "Authorization":getAuthToken()
    //  'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_COLLECTION_MODAL_TAGS}`,data,{ headers: headers }
  );
}
export async function addToCollection(data,assetId) {
  const headers = {
    "Authorization":getAuthToken(),
    //  'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.ADD_TO_COLLECTION}/${assetId}`,data,{ headers: headers }
  );
}


export async function createNewCollection(data) {
  const headers = {
    "Authorization":getAuthToken()
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.CREATE_NEW_COLLECTION}`,data,{ headers: headers }
  );
}
export async function renameCollection(data,id) {

    const headers = {
       "Authorization":getAuthToken()
    }
  
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.RENAME_COLLECTION}/${id}`,data,{ headers: headers }
  );
}
export async function getCollectionDetail(data) {
  const headers = {
    // 'Authorization': `Bearer ${token}`,
   "Authorization":getAuthToken(),
    'Content-Type': 'multipart/form-data',
    // 'Content-Type': 'application/x-www-form-urlencoded',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_COLLECTION_DETAIL}`,data,{ headers: headers }
  );
}

export async function publicShareCollectionList(data) {
  const headers = {
    // 'Authorization': `Bearer ${token}`,
    Authorization: getAuthToken(),
    // 'Content-Type': 'multipart/form-data',
    'Content-Type': 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.PUBLIC_SHARE_COLLECTION_API}`,
    data,
    { headers: headers }
  );
}

export async function publicShareHistoryList(data) {
  const headers = {
    
    Authorization: getAuthToken(),
    'Content-Type': 'multipart/form-data',
    // 'Content-Type': 'application/x-www-form-urlencoded',
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.PUBLIC_SHARE_HISTORY_API}`,data,
    { headers: headers }
  );
}

export async function publicShareCollectionDelete(data) {
  const headers = {
  
    Authorization: getAuthToken(),
    // 'Content-Type': 'multipart/form-data',
   
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.PUBLIC_SHARE_COLLECTION_DELETE_API}`,data,
    { headers: headers }
  );
}


export async function publicSharePSDelete(data) {
  const headers = {
  
    Authorization: getAuthToken(),
    // 'Content-Type': 'multipart/form-data',
   
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.PUBLIC_SHARE_PS_DELETE_API}`,data,
    { headers: headers }
  );
}








export async function getCollectionPublicShareList(data,payload) {
 
  const headers = {
    // Authorization: getAuthToken(),
    'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_COLLECTION_PUBLIC_SHARE_LIST}/${data}`,payload,{ headers: headers }
  );
}
export async function getPublicShareList(data,payload) {
  
  
  const headers = {
    // 'Authorization': `Bearer ${token}`,
    
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_PUBLIC_SHARE_LIST}/${data}`,payload,{ headers: headers }
  );
}









export async function deleteCollectionDetailAsset(data,id) {
  const headers = {
    // 'Authorization': `Bearer ${token}`,
      "Authorization":getAuthToken(),
    // 'Content-Type': 'multipart/form-data',
    // 'Content-Type': 'application/json',
    
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.DELETE_COLLECTION_DETAIL_ASSET}/${id}`,data,{ headers: headers }
  );
}
export async function uploadSingleAssetFile(data) {
  
  const headers = {
    "Authorization":getAuthToken(),
   
    'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPLOAD_SINGLEASSET_FILE}`,data,{ headers: headers }
  );
}

export async function publicShareDownload(data) {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.PUBLIC_SHARE_DOWNLOAD_API}`,
    data,
    { headers: headers }
  );
}

export async function publicShareCollectionDownload(data) {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.PUBLIC_SHARE_COLLECTION_DOWNLOAD_API}`,
    data,
    { headers: headers }
  );
}

export async function breadcrumCreateFolder(data) {
  const headers = {
    "Authorization":getAuthToken(),
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.BREADCRUM_CREATE_FOLDER}`,
    data,
    { headers: headers }
  );
}
export async function replaceAssetFile(data) {
  const headers = {
    Authorization: getAuthToken(),
    
    "Content-Type": "multipart/form-data",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.REPLACE_ASSET}`,
    data,
    { headers: headers }
  );
}



export async function deleteAsset(payload) {
  const headers = {
     "Authorization":getAuthToken()
    //  'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.DELETE_ASSET}`,payload,{ headers: headers }
  );
}

export async function renameFolder(payload) {
  const headers = {
    "Authorization":getAuthToken(),
     'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.RENAME_FOLDER}`,payload,{ headers: headers }
  );
}
export async function addFolder(payload) {
  const headers = {
  "Authorization":getAuthToken(),
      'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.ADD_FOLDER}`,payload,{ headers: headers }
  );
}


export async function deleteFolder(payload) {
  const headers = {
    // "Authorization":"DAM 4w8KE5X446OGAQZx5BMJ"
    //  'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
    "Authorization":getAuthToken(),
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.DELETE_FOLDER}`,payload,{ headers: headers }
  );
}

export async function getFolders(data) {
  const headers = {
    "Authorization":getAuthToken()
    //  'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_FOLDERS}`,data,{ headers: headers }
  );
}



export async function addToCart(data) {
  const headers = {
    "Authorization":getAuthToken()
    // 'Authorization': `Bearer ${token}`,
  }
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.ADD_TO_CART}`, data, { headers: headers })
}

export async function updateAssetMetadata(data,assetId) {
  const headers = {
     "Authorization":getAuthToken(),
     'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPDATE_ASSET_METADATA}?assetId=${assetId}`,data,{ headers: headers }
  );
}

export async function downloadSelectedAssets(data) {
  const headers = {
     "Authorization":getAuthToken(),
     'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.BULK_DOWNLOAD_ASSET}`,data,{ headers: headers }
  );
}

export async function getUserCart(data) {
  const headers = {
     "Authorization":getAuthToken(),
    //  'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.USER_CART}`,data,{ headers: headers }
  );
}

export async function deleteCartItem(data) {
  const headers = {
     "Authorization":getAuthToken(),
     'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.DELETE_CART_ITEM}`,data,{ headers: headers }
  );
}

export async function clearCart(data) {
  const headers = {
     "Authorization":getAuthToken(),
    //  'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.CLEAR_CART}`,data,{ headers: headers }
  );
}

export async function batchMetaDataUpdate(data) {
  const headers = {
      "Authorization":getAuthToken(),
     'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.BATCH_META_DATA_UPDATE}`,data,{ headers: headers }
  );
}
export async function batchUpdate(data) {
  const headers = {
     "Authorization":getAuthToken(),
     'Content-Type': 'application/x-www-form-urlencoded',
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.BATCH_UPDATE}`,data,{ headers: headers }
  );
}


export async function customDownloadApi(data) {
  const headers = {
      "Authorization":getAuthToken(),
   
    //  'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.BULK_DOWNLOAD}`,data,{ headers: headers }
  );
}

export async function customEditCartApi(data) {
  const headers = {
     "Authorization":getAuthToken(),
    // 'Content-Type': 'application/x-www-form-urlencoded',
    'Content-Type': 'multipart/form-data',
  }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.EDIT_CART_CUSTOM}`,data,{ headers: headers }
  );
}


export async function saveFilterApi(data) {
  const headers = {
    "Authorization":getAuthToken(),
   // 'Content-Type': 'application/x-www-form-urlencoded',
   
 }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.SAVE_FILTER}`,data,{ headers: headers }
  );
}


export async function getAllFiltersApi(data) {
  const headers = {
    "Authorization":getAuthToken(),
   // 'Content-Type': 'application/x-www-form-urlencoded',
   
 }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_SAVED_FILTERS}`,data,{ headers: headers }
  );
}




export async function deleteSavedFiltersApi(data) {
  const headers = {
    "Authorization":getAuthToken(),
   // 'Content-Type': 'application/x-www-form-urlencoded',
   
 }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.DELETE_SAVED_FILTERS}`,data,{ headers: headers }
  );
}


export async function getLanguageApi(data) {
  const headers = {
    "Authorization":getAuthToken(),
   // 'Content-Type': 'application/x-www-form-urlencoded',
   
 }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_LANGUAGE_API}`,data,{ headers: headers }
  );
}


export async function publicShareApi(data) {
  const headers = {
    "Authorization":getAuthToken(),
   // 'Content-Type': 'application/x-www-form-urlencoded',
   
 }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.PUBLIC_SHARE_API}`,
    data,
    { headers: headers }
  );
}


export async function collectionShareApi(data) {
  const headers = {
    "Authorization":getAuthToken(),
   // 'Content-Type': 'application/x-www-form-urlencoded',
   
 }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.COLLECTION_SHARE_API}`,
    data,
    { headers: headers }
  );
}


export async function cartDownloadApi(data) {
  const headers = {
    "Authorization":getAuthToken(),
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Content-Type': 'multipart/form-data',
   
 }
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.CART_DOWNLOAD_API}`,
    data,
    { headers: headers }
  );
}






